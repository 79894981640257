import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeInput, searchInput, changeFont } from '../store/modules/webfont';
import Webfont from '../components/webfont';

const WebfontContainer = () => {
  const dispatch = useDispatch();
  const { input, search, fontArray, searchFont } = useSelector(({ webfont }) => ({
    input: webfont.input,
    search: webfont.search,
    fontArray: webfont.fontArray,
    searchFont: webfont.searchFont,
  }));

  const handleChange = e => {
    const { value } = e.target;

    dispatch(changeInput(value));
  }

  const handleSearch = e => {
    // 검색값 입력
    const { value } = e.target;

    dispatch(searchInput(value));

    // 검색값 sort
    const dataSort = () => {
      fontArray.sort();
      
      let data = fontArray.filter(d => {
        const checkKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

        if ( checkKor.test(value) ) {
          return d.kor.indexOf(value) > -1;
        } else {
          return d.eng.toLowerCase().indexOf(value.toLowerCase()) > -1;
        }
      });
      
      dispatch(changeFont(data));
    }

    dataSort();
  }


  return (
    <Webfont input={input} fontArray={fontArray} searchFont={searchFont} onChange={handleChange} onSearch={handleSearch} />
  );
};

export default WebfontContainer;