import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useState } from 'react';

const TITLE = 'CSS Reference - Transitions';

const Transitions = ({ input, value, onChange, onPickerChange, onSubmit }) => {


    // 서브 카테고리 연결
    const transitionDelay = useRef(null);
    const transitionDuration = useRef(null);
    const transitionProperty = useRef(null);
    const transitionTiming = useRef(null);
    const transition = useRef(null);

    const scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // 클릭 시 복사
    const clickCopyText = (event) => {
        const textToCopy = event.target.innerText;
        navigator.clipboard.writeText(textToCopy).then(() => {
            event.target.classList.add('copied');
            setTimeout(() => {
                event.target.classList.remove('copied');
            }, 1000);
        }).catch(err => {
            console.error('텍스트 복사 실패', err);
        });
    }

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
                <meta name="description" content="" />
            </Helmet>

            <div className="top-title css">
                <h2>CSS Reference</h2>
                <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
            </div>

            <div className="menu-wrap">
                <div className="menu-type css">
                    <ul>
                        <Link to="/css-ref"><li>All</li></Link>
                        <Link to="/css-ref/animations"><li>Animations</li></Link>
                        <Link to="/css-ref/backgrounds"><li>Backgrounds</li></Link>
                        <Link to="/css-ref/boxmodel"><li>Box model</li></Link>
                        <Link to="/css-ref/flexbox"><li>Flexbox</li></Link>
                        <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
                        <Link to="/css-ref/positioning"><li>Positioning</li></Link>
                        <Link to="/css-ref/transitions"><li className='checked'>Transitions</li></Link>
                        <Link to="/css-ref/typography"><li>Typography</li></Link>
                    </ul>
                </div>

                <div className="menu-type css category">
                    <ul>
                        <li onClick={() => scrollToRef(transitionDelay)}>transition-delay</li>
                        <li onClick={() => scrollToRef(transitionDuration)}>transition-duration</li>
                        <li onClick={() => scrollToRef(transitionProperty)}>transition-property</li>
                        <li onClick={() => scrollToRef(transitionTiming)}>transition-timing-function</li>
                        <li onClick={() => scrollToRef(transition)}>transition</li>
                    </ul>
                </div>
            </div>

            <div className="css-reference-content" ref={transitionDelay}>
                <div className="name">transition-delay</div>
                <div className="define">Defines how long the transition has to wait before starting.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-delay: 0s;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The transition will wait zero seconds, and thus start right away.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition delay type1">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-delay: 1.2s;</div>
                        </div>
                        <div className="property-define">
                            You can use decimal values in seconds with the keyword s.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition delay type2">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-delay: 2400ms;</div>
                        </div>
                        <div className="property-define">
                            You can use milliseconds instead of seconds, with the keyword ms.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition delay type3">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-delay: -500ms;</div>
                        </div>
                        <div className="property-define">
                            You can use negative values: the transition will start as if it had already been playing for 500ms.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition delay type4">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={transitionDuration}>
                <div className="name">transition-duration</div>
                <div className="define">Defines how long the transition lasts.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-duration: 0s;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The transition will last zero seconds, and is thus instant.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition duration type1">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-duration: 1.2s;</div>
                        </div>
                        <div className="property-define">
                            You can use decimal values in seconds with the keyword s.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition duration type2">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-duration: 2400ms;</div>
                        </div>
                        <div className="property-define">
                            You can use milliseconds instead of seconds, with the keyword ms.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition duration type3">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={transitionProperty}>
                <div className="name">transition-property</div>
                <div className="define">Defines which properties will transition.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-property: all;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will transition all properties:
                            <ul>
                                <li>background</li>
                                <li>color</li>
                                <li>transform</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition property type1">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-property: none;</div>
                        </div>
                        <div className="property-define">
                            The element will transition no property: the transition is thus instant.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition property type2">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-property: background;</div>
                        </div>
                        <div className="property-define">
                            The element will only transtion the background property.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition property type3">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-property: color;</div>
                        </div>
                        <div className="property-define">
                            The element will only transtion the color property.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition property type4">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-property: transform;</div>
                        </div>
                        <div className="property-define">
                            The element will only transtion the transform property.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition property type5">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={transitionTiming}>
                <div className="name">transition-timing-function</div>
                <div className="define">Defines how the values between the start and the end of the transition are calculated.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: ease;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The transition starts slowly, accelerates in the middle, and slows down at the end.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type1">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: ease-in;</div>
                        </div>
                        <div className="property-define">
                            The transition starts slowly, and accelerates gradually until the end.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type2">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: ease-out;</div>
                        </div>
                        <div className="property-define">
                            The transition starts quickly, and decelerates gradually until the end.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type3">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: ease-in-out;</div>
                        </div>
                        <div className="property-define">
                            Like ease, but more pronounced.<br />
                            The transition starts quickly, and decelerates gradually until the end.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type4">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: linear;</div>
                        </div>
                        <div className="property-define">
                            The transition has a *constant speed.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type5">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: step-start;</div>
                        </div>
                        <div className="property-define">
                            The transition jumps instantly to the final state.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type6">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: step-end;</div>
                        </div>
                        <div className="property-define">
                            The transition stays at the initial state until the end, when it instantly jumps to the final state.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type7">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition-timing-function: steps(4, end);</div>
                        </div>
                        <div className="property-define">
                            By using steps() with an integer, you can define a specific number of steps before reaching the end. The state of the element will not vary gradually, but rather jump from state to state in separate instants.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition timing type8">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={transition}>
                <div className="name">transition</div>
                <div className="define">Shorthand property for transition-property transition-duration transition-timing-function and transition-delay.

                    Only transition-duration is required.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition: 1s;</div>
                        </div>
                        <div className="property-define">
                            <ul>
                                <li>transition-duration is set to 1s</li>
                                <li> transition-property defaults to all</li>
                                <li>transition-timing-function defaults to ease</li>
                                <li> transition-delay defaults to 0s</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition basic type1">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition: 1s linear;</div>
                        </div>
                        <div className="property-define">
                            <ul>
                                <li>transition-duration is set to 1s</li>
                                <li>transition-property defaults to all</li>
                                <li>transition-timing-function is set to linear</li>
                                <li>transition-delay defaults to 0s</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition basic type2">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition: background 1s linear;</div>
                        </div>
                        <div className="property-define">
                            <ul>
                                <li>transition-duration is set to 1s</li>
                                <li>transition-property is set to background</li>
                                <li>transition-timing-function is set to linear</li>
                                <li>transition-delay defaults to 0s</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition basic type3">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition: transition: background 1s linear 500ms;</div>
                        </div>
                        <div className="property-define">
                            <ul>
                                <li>transition-duration is set to 1s</li>
                                <li>transition-property is set to background</li>
                                <li>transition-timing-function is set to linear</li>
                                <li>transition-delay is set to 500ms</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition basic type4">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>transition: transition: background 4s, transform 1s;</div>
                        </div>
                        <div className="property-define">
                            You can combine multiple properties with their own transition duration.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output transition basic type5">
                                    <p className="square square-alpha">Hover me</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Transitions;