import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useState } from 'react';

const TITLE = 'CSS Reference - Animations';

const Animations = ({ input, value, onChange, onPickerChange, onSubmit }) => {


  // 서브 카테고리 연결
  const delay = useRef(null);
  const direction = useRef(null);
  const duration = useRef(null);
  const fillMode = useRef(null);
  const iterationCount = useRef(null);
  const name = useRef(null);
  const playState = useRef(null);
  const timingFunction = useRef(null);
  const animation = useRef(null);

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // 클릭 시 복사
  const clickCopyText = (event) => {
    const textToCopy = event.target.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      event.target.classList.add('copied');
      setTimeout(() => {
        event.target.classList.remove('copied');
      }, 1000);
    }).catch(err => {
      console.error('텍스트 복사 실패', err);
    });
  }

  //toggle
  const [animationLive, setAniOn] = useState(false);
  const [animationLive2, setAniOn2] = useState(false);
  const [animationLive3, setAniOn3] = useState(false);
  const [animationLive4, setAniOn4] = useState(false);
  const [animationLive5, setAniOn5] = useState(false);
  const [animationLive6, setAniOn6] = useState(false);
  const [animationLive7, setAniOn7] = useState(false);
  const [animationLive8, setAniOn8] = useState(false);

  const toggleClass = () => {
    setAniOn(!animationLive);
  };

  const toggleClass2 = () => {
    setAniOn2(!animationLive2);
  };

  const toggleClass3 = () => {
    setAniOn3(!animationLive3);
  };

  const toggleClass4 = () => {
    setAniOn4(!animationLive4);
  };

  const toggleClass5 = () => {
    setAniOn5(!animationLive5);
  };

  const toggleClass6 = () => {
    setAniOn6(!animationLive6);
  };

  const toggleClass7 = () => {
    setAniOn7(!animationLive7);
  };

  const toggleClass8 = () => {
    setAniOn8(!animationLive8);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="top-title css">
        <h2>CSS Reference</h2>
        <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
      </div>

      <div className="menu-wrap">
        <div className="menu-type css">
          <ul>
            <Link to="/css-ref"><li>All</li></Link>
            <Link to="/css-ref/animations"><li className='checked'>Animations</li></Link>
            <Link to="/css-ref/backgrounds"><li>Backgrounds</li></Link>
            <Link to="/css-ref/boxmodel"><li>Box model</li></Link>
            <Link to="/css-ref/flexbox"><li>Flexbox</li></Link>
            <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
            <Link to="/css-ref/positioning"><li>Positioning</li></Link>
            <Link to="/css-ref/transitions"><li>Transitions</li></Link>
            <Link to="/css-ref/typography"><li>Typography</li></Link>
          </ul>
        </div>

        <div className="menu-type css category">
          <ul>
            <li onClick={() => scrollToRef(delay)}>animation-delay</li>
            <li onClick={() => scrollToRef(direction)}>animation-direction</li>
            <li onClick={() => scrollToRef(duration)}>animation-duration</li>
            <li onClick={() => scrollToRef(fillMode)}>animation-fill-mode</li>
            <li onClick={() => scrollToRef(iterationCount)}>animation-iteration-count</li>
            <li onClick={() => scrollToRef(name)}>animation-name</li>
            <li onClick={() => scrollToRef(playState)}>animation-play-state</li>
            <li onClick={() => scrollToRef(timingFunction)}>animation-timing-function</li>
            <li onClick={() => scrollToRef(animation)}>animation</li>
          </ul>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={delay}>
        <div className="css-reference-content">
          <div className="name">animation-delay</div>
          <div className="define">Defines how long the animation has to wait before starting. The animation will only be delayed on its first iteration.</div>

          <div className={`button-toggle play-ani ${animationLive ? 'off' : ''}`} onClick={toggleClass}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-delay: 0s;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                The animation will wait zero seconds, and thus start right away.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square delay type1 ${animationLive ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-delay: 1.2s;</div>
              </div>
              <div className="property-define">
                You can use decimal values in seconds with the keyword s.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square delay type2 ${animationLive ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-delay: 2400ms;</div>
              </div>
              <div className="property-define">
                You can use milliseconds instead of seconds, with the keyword ms.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square delay type3 ${animationLive ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-delay: -500ms;</div>
              </div>
              <div className="property-define">
                animation-delay: -500ms;
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square delay type4 ${animationLive ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={direction}>
        <div className="css-reference-content">
          <div className="name">animation-direction</div>
          <div className="define">Defines in which direction the animation is played.</div>

          <div className={`button-toggle play-ani ${animationLive2 ? 'off' : ''}`} onClick={toggleClass2}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-direction: normal;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                The animation is played forwards. When it reaches the end, it starts over at the first keyframe.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square direction type1 ${animationLive2 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-direction: reverse;</div>
              </div>
              <div className="property-define">
                The animation is played backwards: begins at the last keyframe, finishes at the first keyframe.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square direction type2 ${animationLive2 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-direction: alternate;</div>
              </div>
              <div className="property-define">
                The animation is played forwards first, then backwards:
                <ul>
                  <li>starts at the first keyframe</li>
                  <li>stops at the last keyframe</li>
                  <li>starts again, but at the last keyframe</li>
                  <li>stops at the first keyframe</li>
                </ul>
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square direction type3 ${animationLive2 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-direction: alternate-reverse;</div>
              </div>
              <div className="property-define">
                The animation is played backwards first, then forwards:
                <ul>
                  <li>starts at the last keyframe</li>
                  <li>stops at the first keyframe</li>
                  <li>starts again, but at the first keyframe</li>
                  <li>stops at the last keyframe</li>
                </ul>
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square direction type4 ${animationLive2 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={duration}>
        <div className="css-reference-content">
          <div className="name">animation-duration</div>
          <div className="define">Defines how long the animation lasts.</div>

          <div className={`button-toggle play-ani ${animationLive3 ? 'off' : ''}`} onClick={toggleClass3}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-duration: 0s;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                The default value is zero seconds: the animation will simply not play.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square duration type1 ${animationLive3 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-duration: 1.2s;</div>
              </div>
              <div className="property-define">
                You can use decimal values in seconds with the keyword s.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square duration type2 ${animationLive3 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-duration: 2400ms;</div>
              </div>
              <div className="property-define">
                You can use milliseconds instead of seconds, with the keyword ms.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square duration type3 ${animationLive3 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div className="css-reference-content-wrap" ref={fillMode}>
        <div className="css-reference-content">
          <div className="name">animation-fill-mode</div>
          <div className="define">Defines what happens before an animation starts and after it ends. The fill mode allows to tell the browser if the animation’s styles should also be applied outside of the animation.</div>

          <div className={`button-toggle play-ani ${animationLive4 ? 'off' : ''}`} onClick={toggleClass4}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-fill-mode: none;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                The animation styles do not affect the default style: the element is set to its default state before the animation starts, and returns to that default state after the animation ends.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square fill-mode type1 ${animationLive4 ? 'ani-on2' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-fill-mode: forwards;</div>
              </div>
              <div className="property-define">
                The last styles applied at the end of the animation are retained afterwards.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square fill-mode type2 ${animationLive4 ? 'ani-on2' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-fill-mode: backwards;</div>
              </div>
              <div className="property-define">
                The animation's styles will already be applied before the animation actually starts.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square fill-mode type3 ${animationLive4 ? 'ani-on2' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-fill-mode: both;</div>
              </div>
              <div className="property-define">
                The styles are applied before and after the animation plays.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square fill-mode type4 ${animationLive4 ? 'ani-on2' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={iterationCount}>
        <div className="css-reference-content">
          <div className="name">animation-iteration-count</div>
          <div className="define">Defines how many times the animation is played.</div>

          <div className={`button-toggle play-ani ${animationLive5 ? 'off' : ''}`} onClick={toggleClass5}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-iteration-count: 1;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                The animation will only play once.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square iteration-count type1 ${animationLive5 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-iteration-count: 2;</div>
              </div>
              <div className="property-define">
                You can use integer values to define a specific amount of times the animation will play.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square iteration-count type2 ${animationLive5 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-iteration-count: infinite;</div>
              </div>
              <div className="property-define">
                By using the keyword infinite, the animation will play indefinitely.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square iteration-count type3 ${animationLive5 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={name}>
        <div className="css-reference-content">
          <div className="name">animation-name</div>
          <div className="define">Defines which animation keyframes to use.</div>

          <div className={`button-toggle play-ani ${animationLive6 ? 'off' : ''}`} onClick={toggleClass6}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-name: none;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                If no animation name is specified, no animation is played.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square name type1 ${animationLive6 ? 'ani-on3' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-name: fadeIn;</div>
              </div>
              <div className="property-define">
                If a name is specified, the keyframes matching that name will be used.<br />
                For example, the fadeIn animation looks like this:
                <div className="ani-example">
                  fadeIn (수정 필요)
                </div>
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square name type2 ${animationLive6 ? 'ani-on3' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-name: moveRight;</div>
              </div>
              <div className="property-define">
                Another example: the moveRight animation:
                <div className="ani-example">
                  moveRight (수정 필요)
                </div>
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square name type3 ${animationLive6 ? 'ani-on3' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={playState}>
        <div className="css-reference-content">
          <div className="name">animation-play-state</div>
          <div className="define">Defines if an animation is playing or not.</div>

          <div className={`button-toggle play-ani ${animationLive7 ? 'off' : ''}`} onClick={toggleClass7}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-play-state: running;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                If the animation-duration and animation-name are defined, the animation will start playing automatically.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square play-state type1 ${animationLive7 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-play-state: running;</div>
              </div>
              <div className="property-define">
                The animation is set paused at the first keyframe.<br />

                This is different than having either no animation-duration or animation-name at all. If the animation is paused, the style applied is that of the first keyframe, and not the default style.<br />

                In this example, the square is visible by default, but the on the first keyframe of fadeAndMove, the opacity is set to 0. When paused, the animation will be "stuck" on this first keyframe, and will thus be invisible.<br />

                fadeAndMove (수정필요)
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square play-state type2 ${animationLive7 ? 'ani-on' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={timingFunction}>
        <div className="css-reference-content">
          <div className="name">animation-timing-function</div>
          <div className="define">Defines how the values between the start and the end of the animation are calculated.</div>

          <div className={`button-toggle play-ani ${animationLive8 ? 'off' : ''}`} onClick={toggleClass8}></div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: ease;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>

              </div>
              <div className="property-define">
                The animation starts slowly, accelerates in the middle, and slows down at the end.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type1 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: ease-in;</div>
              </div>
              <div className="property-define">
                The animation starts slowly, and accelerates gradually until the end.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type2 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: ease-out;</div>
              </div>
              <div className="property-define">
                The animation starts quickly, and decelerates gradually until the end.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type3 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: ease-in-out;</div>
              </div>
              <div className="property-define">
                Like ease, but more pronounced.<br />
                The animation starts quickly, and decelerates gradually until the end.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type4 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: linear;</div>
              </div>
              <div className="property-define">
                The animation has a *constant speed.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type5 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: step-start;</div>
              </div>
              <div className="property-define">
                The animation jumps instantly to the final state.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type6 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: step-end;</div>
              </div>
              <div className="property-define">
                The animation stays at the initial state until the end, when it instantly jumps to the final state.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type7 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>animation-timing-function: steps(4, end);</div>
              </div>
              <div className="property-define">
                By using steps() with an integer, you can define a specific number of steps before reaching the end. The state of the element will not vary gradually, but rather jump from state to state in separate instants.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className={`example-background-output animations square timing-function type8 ${animationLive8 ? 'ani-on4' : ''}`}>
                    Hello<br />World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="css-reference-content-wrap" ref={animation}>
        <div className="css-reference-content">
          <div className="name">animation</div>
          <div className="define">
            Shorthand property for animation-name animation-duration animation-timing-function animation-delay animation-iteration-count animation-direction animation-fill-mode and animation-play-state.<br />
            Only animation-duration and animation-name are required.</div>
        </div>
      </div>
    </>
  );
};

export default Animations;