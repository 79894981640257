import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './NanumBarunPen.scss';

class NanumBarunPen extends Component {
  render() {
    const {input, onChange} = this.props;

    return (
      <li>
        <Link to="/nanum_barunpen/detail">
          <div className="dashed-wrap nanum_barunpen">
            <h4 className="content-title">
              <span className="font-company">Naver, Sandoll</span>
              <span className="font-title">나눔 바른 펜 / Nanum Barun Pen</span>
            </h4>
            <p className="font-text">{input}</p>
          </div>
        </Link>
      </li>
    );
  }
}

export default NanumBarunPen;