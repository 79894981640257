import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useState } from 'react';

const TITLE = 'CSS Reference - Backgrounds';

const Backgrounds = ({ input, value, onChange, onPickerChange, onSubmit }) => {


  // 서브 카테고리 연결
  const attachmentRef = useRef(null);
  const clipRef = useRef(null);
  const colorRef = useRef(null);
  const imageRef = useRef(null);
  const originRef = useRef(null);
  const positionRef = useRef(null);
  const repeatRef = useRef(null);
  const sizeRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // 클릭 시 복사
  const clickCopyText = (event) => {
    const textToCopy = event.target.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      event.target.classList.add('copied');
      setTimeout(() => {
        event.target.classList.remove('copied');
      }, 1000);
    }).catch(err => {
      console.error('텍스트 복사 실패', err);
    });
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="top-title css">
        <h2>CSS Reference</h2>
        <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
      </div>

      <div className="menu-wrap">
        <div className="menu-type css">
          <ul>
            <Link to="/css-ref"><li>All</li></Link>
            <Link to="/css-ref/animations"><li>Animations</li></Link>
            <Link to="/css-ref/backgrounds"><li className='checked'>Backgrounds</li></Link>
            <Link to="/css-ref/boxmodel"><li>Box model</li></Link>
            <Link to="/css-ref/flexbox"><li>Flexbox</li></Link>
            <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
            <Link to="/css-ref/positioning"><li>Positioning</li></Link>
            <Link to="/css-ref/transitions"><li>Transitions</li></Link>
            <Link to="/css-ref/typography"><li>Typography</li></Link>
          </ul>
        </div>

        <div className="menu-type css category">
          <ul>
            <li onClick={() => scrollToRef(attachmentRef)}>background-attachment</li>
            <li onClick={() => scrollToRef(clipRef)}>background-clip</li>
            <li onClick={() => scrollToRef(colorRef)}>background-color</li>
            <li onClick={() => scrollToRef(imageRef)}>background-image</li>
            <li onClick={() => scrollToRef(originRef)}>background-origin</li>
            <li onClick={() => scrollToRef(positionRef)}>background-position</li>
            <li onClick={() => scrollToRef(repeatRef)}>background-repeat</li>
            <li onClick={() => scrollToRef(sizeRef)}>background-size</li>
            {/* <li onClick={() => scrollToRef(clipRef)}>background</li> */}
          </ul>
        </div>
      </div>

      {/* attachment */}
      <div className="css-reference-content-wrap" ref={attachmentRef}>
        <div className="css-reference-content">
          <div className="name">background-attachment</div>
          <div className="define">Defines how the background image will behave when scrolling the page.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-attachment: scroll;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
                
              </div>
              <div className="property-define">The background image will scroll with the page. It will also position and resize itself according to the element it's applied to.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output attachment scroll">
                    <p>Hello World</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-attachment: fixed;</div>
              <div className="property-define">The background image will not scroll with the page, and remain positioned according to the viewport. It will also position and resize itself according to the viewport. As a result, the background image will probably only be partially visible.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output attachment fixed">
                    <p>Hello World</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* clip */}
      <div className="css-reference-content-wrap" ref={clipRef}>
        <div className="css-reference-content">
          <div className="name">background-clip</div>
          <div className="define">Defines how far the background should extend within the element.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-clip: border-box;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">The background extends completely throughout the element, even under the border.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output clip border-box">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-clip: padding-box;</div>
              <div className="property-define">The background only extends to the edge of the border: it includes the padding but not the border.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output clip padding-box">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-clip: content-box;</div>
              <div className="property-define">The background only extends to the edge of the content: it doesn't include the padding, nor the border.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output clip content-box">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* color */}
      <div className="css-reference-content-wrap" ref={colorRef}>
        <div className="css-reference-content">
          <div className="name">background-color</div>
          <div className="define">Defines the color of the element's background.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-color: transparent;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">By default, the background color is transparent, basically meaning that there is no background color.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output color transparent">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-color: red;</div>
              <div className="property-define">You can use one of the 140+ color names.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output color red">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-color: #05ffb0;</div>
              <div className="property-define">You can use hexadecimal color codes.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output color select">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-color: rgb(50, 115, 220);</div>
              <div className="property-define">
                You can use rgb() color codes:<br />

                <ul>
                  <li>the first value is for red</li>
                  <li>the second value is for green</li>
                  <li>the third value is for blue</li>
                </ul>

                Each of them can have a value between 0 and 255.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output color rgb">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-color: rgba(50, 115, 220, 0.3);</div>
              <div className="property-define">
                You can use rgba() color codes:
                <ul>
                  <li>the first 3 values are for rgb</li>
                  <li>the 4th value is for the alpha channel and defines the opacity of the color</li>
                </ul>
                The alpha value can go from zero 0 (transparent) to one 1 (opaque).
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output color rgba">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-color: hsl(14, 100%, 53%);</div>
              <div className="property-define">
                You can use hsl() color codes:
                <ul>
                  <li>the first value is for hue and can go from 0 to 359</li>
                  <li>the second value is for saturation and go from 0% to 100%</li>
                  <li>the third value is for luminosity and go from 0% to 100%</li>
                </ul>
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output color hsl">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-color: hsla(14, 100%, 53%, 0.6);</div>
              <div className="property-define">
                You can use hsl()a color codes:
                <ul>
                  <li>the first 3 values are for hsl</li>
                  <li>the 4th value is for the alpha channel and defines the opacity of the color</li>
                </ul>
                The alpha value can go from zero 0 (transparent) to one 1 (opaque).
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output color select">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* image */}
      <div className="css-reference-content-wrap" ref={imageRef}>
        <div className="css-reference-content">
          <div className="name">background-image</div>
          <div className="define">Defines an image as the background of the element.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-image: none;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">Removes any background image.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image none">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: url(/images/jt.png);</div>
              <div className="property-define">Uses the image defined in the url path. This path can either be relative (to the css file) or absolute (like http://cssreference.io/images/jt.png).</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image url">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: linear-gradient(red, blue);</div>
              <div className="property-define">
                You can define a linear gradient as the background image.<br />

                You need to define at least two colors. The first one will start at the top, the second one at the bottom.<br />

                The default angle is to bottom (or 180deg), which means the gradient is vertical, starting at the top, ending at the bottom of the element.<br />
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image gradient1">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: linear-gradient(45deg, red, blue);</div>
              <div className="property-define">
                You can specify an angle, either in degrees, or with keywords.

                When using degress, you specify the direction of the gradient, or when it ends. So 0deg means the the top of the element, like 12:00 on a clock.

                In this example, 45deg means 2:30, or the top right corner.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image gradient2">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: radial-gradient(green, purple);</div>
              <div className="property-define">
                You can define a radial gradient as the background image.

                You need to define at least two colors. The first one will be at the center, the second one at the edges.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image r-gradient1">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: radial-gradient(circle, green, purple);</div>
              <div className="property-define">
                You can specify the shape of the radial gradient: circle or ellipse (default).
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image r-gradient2">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: radial-gradient(circle, green 0%, purple 20%, orange 100%);</div>
              <div className="property-define">
                You can specify color stops using percentage values.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image r-gradient3">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: radial-gradient(circle closest-side, green 0%, purple 20%, orange 100%);</div>
              <div className="property-define">
                You can specify where the gradient should end:
                <ul>
                  <li>closest-side</li>
                  <li>closest-corner</li>
                  <li>farthest-side</li>
                  <li>farthest-corner</li>
                </ul>
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image r-gradient4">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-image: radial-gradient(circle closest-side at 45px 45px, green 0%, purple 20%, orange 100%);</div>
              <div className="property-define">
                Like with the background-position, you can specify the position of the gradient.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output image r-gradient5">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* origin */}
      <div className="css-reference-content-wrap" ref={originRef}>
        <div className="css-reference-content">
          <div className="name">background-origin</div>
          <div className="define">Defines the origin of the background image.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-origin: padding-box;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">The background image starts at the edge of the border: within padding but not the border.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output origin padding-box">
                    <p>Hello World</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-origin: border-box;</div>
              <div className="property-define">The background image starts under the border.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output origin border-box">
                    <p>Hello World</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-origin: content-box;</div>
              <div className="property-define">The background image only starts at the edge of the content: it doesn't include the padding, nor the border.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output origin content-box">
                    <p>Hello World</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* position */}
      <div className="css-reference-content-wrap" ref={positionRef}>
        <div className="css-reference-content">
          <div className="name">background-position</div>
          <div className="define">Defines the position of the background image.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-position: 0% 0%;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">
                The background image will be positioned at 0% on the horizontal axis and 0% on the vertical axis, which means the top left corner of the element.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output position type1">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-position: bottom right;</div>
              <div className="property-define">You can use a combination of position keywords: center, top, bottom, left and right.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output position type2">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-position: center center;</div>
              <div className="property-define">The background image will be positioned in the center of the element.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output position type3">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* repeat */}
      <div className="css-reference-content-wrap" ref={repeatRef}>
        <div className="css-reference-content">
          <div className="name">background-repeat</div>
          <div className="define">Defines how the background image repeats itself across the element's background, starting from the background position.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-repeat: repeat;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">
                The background image will repeat itself both horizontally and vertically.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output position repeat">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-repeat: repeat-x;</div>
              <div className="property-define">The background image will only repeat itself horizontally.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output position repeat-x">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-repeat: repeat-y;</div>
              <div className="property-define">The background image will only repeat itself vertically.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output position repeat-y">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-repeat: no-repeat;</div>
              <div className="property-define">The background image will only appear once.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output position no-repeat">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* size */}
      <div className="css-reference-content-wrap" ref={sizeRef}>
        <div className="css-reference-content">
          <div className="name">background-size</div>
          <div className="define">Defines the size of the background image.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>background-size: auto auto;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">
                The background image will retain its original size.

                For example, this background image is 960px by 640px large. Its aspect ratio is 3 by 2. It's bigger than its container (which is 150px high) and will thus be clipped.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output size auto">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-size: 120px 80px;</div>
              <div className="property-define">
                You can specify a size in pixels:
                <ul>
                  <li>the first value is the horizontal size</li>
                  <li>the second is the vertical size</li>
                </ul>
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output size pixel">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-size: 100% 50%;</div>
              <div className="property-define">You can use percentage values as well. Beware that this can alter the aspect ratio of the background image, and lead to unexpected results.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output size percentage">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-size: contain;</div>
              <div className="property-define">The keyword contain will resize the background image to make sure it remains fully visible.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output size contain">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>background-size: cover;</div>
              <div className="property-define">The keyword cover will resize the background image to make sure the element is fully covered.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output size cover">
                    Hello World
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Backgrounds;