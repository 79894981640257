import React from 'react';

const DefaultSet = ({ onDefaultSet }) => {
  return (
    <>
      <div className="input-wrap white">
        <label htmlFor="defaultInput">기준 Pixel값 입력</label>
        <p className="input-box">
          <input type="number" id="defaultInput" defaultValue={16} onChange={onDefaultSet} />
          <span className="input-tail">px</span>
        </p>
      </div>
    </>
  );
};

export default DefaultSet;