import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const TITLE = 'CSS Reference';

const CssReference = ({ input, value, onChange, onPickerChange, onSubmit }) => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="top-title css">
        <h2>CSS Reference</h2>
        <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
      </div>

      <div className="menu-wrap">
        <div className="menu-type css">
          <ul>
            <Link to="/css-ref"><li className='checked'>All</li></Link>
            <Link to="/css-ref/animations"><li>Animations</li></Link>
            <Link to="/css-ref/backgrounds"><li>Backgrounds</li></Link>
            <Link to="/css-ref/boxmodel"><li>Box model</li></Link>
            <Link to="/css-ref/flexbox"><li>Flexbox</li></Link>
            <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
            <Link to="/css-ref/positioning"><li>Positioning</li></Link>
            <Link to="/css-ref/transitions"><li>Transitions</li></Link>
            <Link to="/css-ref/typography"><li>Typography</li></Link>
          </ul>
        </div>
      </div>

      <div className="content-wrap">
        <div className="sz-wrap">
          {/* ad 영역 */}
          <div className="ad-wrap" />
        </div>
      </div>

      <div className="css-reference-all">

      </div>

      {/* <div className="css-reference-content-wrap">
        <div className="css-reference-content">
          <div className="name">background-attachment</div>
          <div className="define">Defines how the background image will behave when scrolling the page.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name">background-attachment: scroll;</div>
              <div className="property-define">The background image will scroll with the page. It will also position and resize itself according to the element it's applied to.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output scroll">
                    <p>Hello World</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name">background-attachment: fixed;</div>
              <div className="property-define">The background image will not scroll with the page, and remain positioned according to the viewport. It will also position and resize itself according to the viewport. As a result, the background image will probably only be partially visible.</div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output fixed">
                    <p>Hello World</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CssReference;