import React from 'react';
import { Routes, Route } from 'react-router-dom';

// components
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Main from './components/main';
import Fire from './components/main/Fire';
import Pxtoem from './containers/PxtoemContainer';
import ColorConvert from './containers/ColorConvertContainer';
import Webfont from './containers/WebfontContainer';
import CssReference from './containers/CssReferenceContainer';

// category
import Animations from './components/css-reference/category/animaitons';
import Backgrounds from './components/css-reference/category/backgrounds';
import Boxmodel from './components/css-reference/category/boxmodel';
import Flexbox from './components/css-reference/category/flexbox';
import Cssgrid from './components/css-reference/category/cssgrid';
import Positioning from './components/css-reference/category/positioning';
import Transitions from './components/css-reference/category/transitions';
import Typography from './components/css-reference/category/typography';


// web font components
import NotoSansKRDetail from './containers/font/NotoSansKRContainer';
import GothicA1Detail from './containers/font/GothicA1Container';
import NanumGothicDetail from './containers/font/NanumGothicContainer';
import NanumMyeongjoDetail from './containers/font/NanumMyeongjoContainer';
import NanumBarunGothicDetail from './containers/font/NanumBarunGothicContainer';
import NanumSquareDetail from './containers/font/NanumSquareContainer';
import NanumSquareRoundDetail from './containers/font/NanumSquareRoundContainer';
import NanumBarunPenDetail from './containers/font/NanumBarunPenContainer';
import NanumBrushDetail from './containers/font/NanumBrushContainer';
import GugiDetail from './containers/font/GugiContainer';
import JuaDetail from './containers/font/JuaContainer';
import BlackHanSansDetail from './containers/font/BlackHanSansContainer';
import NixgonDetail from './containers/font/NixgonContainer';

// css
import './assets/css/style.scss';

function App() {
  return (
    <>
      <Header />
      
      <div id="container">
        <Routes>
          <Route path="/" exact element={<Main />} />
          <Route path="/pxtoem" element={<Pxtoem />} />
          <Route path="/color-convert" element={<ColorConvert />} />
          <Route path="/webfont" element={<Webfont />} />
          <Route path="/css-ref" element={<CssReference />} />

          {/* css-ref-category */}
          <Route path="/css-ref/animations" element={<Animations />} />
          <Route path="/css-ref/backgrounds" element={<Backgrounds />} />
          <Route path="/css-ref/boxmodel" element={<Boxmodel />} />
          <Route path="/css-ref/flexbox" element={<Flexbox />} />
          <Route path="/css-ref/cssgrid" element={<Cssgrid />} /> 
          <Route path="/css-ref/positioning" element={<Positioning />} /> 
          <Route path="/css-ref/transitions" element={<Transitions />} /> 
          <Route path="/css-ref/typography" element={<Typography />} />

          {/* web font */}
          <Route path="/notosanskr/detail" element={<NotoSansKRDetail />} />
          <Route path="/gothic_a1/detail" element={<GothicA1Detail />} />
          <Route path="/nanum_gothic/detail" element={<NanumGothicDetail />} />
          <Route path="/nanum_myeongjo/detail" element={<NanumMyeongjoDetail />} />
          <Route path="/nanum_barungothic/detail" element={<NanumBarunGothicDetail />} />
          <Route path="/nanum_square/detail" element={<NanumSquareDetail />} />
          <Route path="/nanum_squareround/detail" element={<NanumSquareRoundDetail />} />
          <Route path="/nanum_barunpen/detail" element={<NanumBarunPenDetail />} />
          <Route path="/nanum_brush/detail" element={<NanumBrushDetail />} />
          <Route path="/gugi/detail" element={<GugiDetail />} />
          <Route path="/jua/detail" element={<JuaDetail />} />
          <Route path="/black_han_sans/detail" element={<BlackHanSansDetail />} />
          <Route path="/nixgon/detail" element={<NixgonDetail />} />
        </Routes>
      </div>

      <Footer />
    </>
  );
}

export default App;
