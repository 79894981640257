import {combineReducers} from 'redux';
import pxtoem from './pxtoem';
import colorConvert from './colorConvert';
import webfont from './webfont';
import cssReference from './cssReference';

export default combineReducers({
    pxtoem,
    colorConvert,
    webfont,
    cssReference,
});