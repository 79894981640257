import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useState } from 'react';

const TITLE = 'CSS Reference - Typography';

const Typography = ({ input, value, onChange, onPickerChange, onSubmit }) => {


    // 서브 카테고리 연결
    const colorRef = useRef(null);
    const fontFamilyRef = useRef(null);
    const fontSizeRef = useRef(null);
    const fontStyleRef = useRef(null);
    const fontVariantRef = useRef(null);
    const fontWeightRef = useRef(null);
    const fontRef = useRef(null);
    const letterSpacingRef = useRef(null);
    const lineHeightRef = useRef(null);
    const textAlignRef = useRef(null);
    const textDecroationRef = useRef(null);
    const textIndentRef = useRef(null);
    const textOverflowRef = useRef(null);
    const textShadowRef = useRef(null);
    const textTransformRef = useRef(null);
    const whiteSpaceRef = useRef(null);
    const wordBreakRef = useRef(null);
    const wordSpacingRef = useRef(null);

    const scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // 클릭 시 복사
    const clickCopyText = (event) => {
        const textToCopy = event.target.innerText;
        navigator.clipboard.writeText(textToCopy).then(() => {
            event.target.classList.add('copied');
            setTimeout(() => {
                event.target.classList.remove('copied');
            }, 1000);
        }).catch(err => {
            console.error('텍스트 복사 실패', err);
        });
    }

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
                <meta name="description" content="" />
            </Helmet>

            <div className="top-title css">
                <h2>CSS Reference</h2>
                <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
            </div>

            <div className="menu-wrap">
                <div className="menu-type css">
                    <ul>
                        <Link to="/css-ref"><li>All</li></Link>
                        <Link to="/css-ref/animations"><li>Animations</li></Link>
                        <Link to="/css-ref/backgrounds"><li>Backgrounds</li></Link>
                        <Link to="/css-ref/boxmodel"><li>Box model</li></Link>
                        <Link to="/css-ref/flexbox"><li>Flexbox</li></Link>
                        <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
                        <Link to="/css-ref/positioning"><li>Positioning</li></Link>
                        <Link to="/css-ref/transitions"><li>Transitions</li></Link>
                        <Link to="/css-ref/typography"><li className='checked'>Typography</li></Link>
                    </ul>
                </div>

                <div className="menu-type css category">
                    <ul>
                        <li onClick={() => scrollToRef(colorRef)}>color</li>
                        <li onClick={() => scrollToRef(fontFamilyRef)}>font-family</li>
                        <li onClick={() => scrollToRef(fontSizeRef)}>font-size</li>
                        <li onClick={() => scrollToRef(fontStyleRef)}>font-style</li>
                        <li onClick={() => scrollToRef(fontVariantRef)}>font-variant</li>
                        <li onClick={() => scrollToRef(fontWeightRef)}>font-weight</li>
                        <li onClick={() => scrollToRef(fontRef)}>font</li>
                        <li onClick={() => scrollToRef(letterSpacingRef)}>letter-spacing</li>
                        <li onClick={() => scrollToRef(lineHeightRef)}>line-height</li>
                        <li onClick={() => scrollToRef(textAlignRef)}>text-align</li>
                        <li onClick={() => scrollToRef(textDecroationRef)}>text-decoration</li>
                        <li onClick={() => scrollToRef(textIndentRef)}>text-indent</li>
                        <li onClick={() => scrollToRef(textOverflowRef)}>text-overflow</li>
                        <li onClick={() => scrollToRef(textShadowRef)}>text-shadow</li>
                        <li onClick={() => scrollToRef(textTransformRef)}>text-transform</li>
                        <li onClick={() => scrollToRef(whiteSpaceRef)}>white-spacing</li>
                        <li onClick={() => scrollToRef(wordBreakRef)}>word-break</li>
                        <li onClick={() => scrollToRef(wordSpacingRef)}>word-spacing</li>
                    </ul>
                </div>
            </div>

            <div className="css-reference-content" ref={colorRef}>
                <div className="name">color</div>
                <div className="define">Defines the color of the text.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>color: transparent;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Applies a transparent color to the text. The text will still take up the space it should.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo color type1">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>color: red;</div>
                        </div>
                        <div className="property-define">
                            You can use one of the 140+ color names.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo color type2">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>color: #05ffb0;</div>
                        </div>
                        <div className="property-define">
                            You can use hexadecimal color codes.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo color type3">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>color: rgb(50, 115, 220);</div>
                        </div>
                        <div className="property-define">
                            You can use rgb() color codes:
                            <ul>
                                <li>the first value is for red</li>
                                <li>the second value is for green</li>
                                <li>the third value is for blue</li>
                            </ul>
                            Each of them can have a value between 0 and 255.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo color type4">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>color: rgba(0, 0, 0, 0.5);</div>
                        </div>
                        <div className="property-define">
                            You can use rgba() color codes:
                            <ul>
                                <li>the first 3 values are for rgb</li>
                                <li>the 4th value is for the alpha channel and defines the opacity of the color</li>
                            </ul>
                            The alpha value can go from zero 0 (transparent) to one 1 (opaque).
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo color type5">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>color: hsl(14, 100%, 53%);</div>
                        </div>
                        <div className="property-define">
                            You can use hsl() color codes:
                            <ul>
                                <li>the first value is for hue and can go from 0 to 359</li>
                                <li>the second value is for saturation and go from 0% to 100%</li>
                                <li>the third value is for luminosity and go from 0% to 100%</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo color type6">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>color: hsla(14, 100%, 53%, 0.6);</div>
                        </div>
                        <div className="property-define">
                            You can use hsl()a color codes:
                            <ul>
                                <li>the first 3 values are for hsl</li>
                                <li>the 4th value is for the alpha channel and defines the opacity of the color</li>
                            </ul>
                            The alpha value can go from zero 0 (transparent) to one 1 (opaque).
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo color type7">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="css-reference-content" ref={fontFamilyRef}>
                <div className="name">font-family</div>
                {/* <div className="define"></div> */}

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-family: "Source Sans Pro", "Arial", sans-serif;</div>
                            {/* <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div> */}
                        </div>
                        <div className="property-define">
                            When using multiple values, the font-family list of font families defines the priority in which the browser should choose the font family.<br />

                            The browser will look for each family on the user's computer and in any @font-face resource.<br />

                            The list is prioritized from left to right: it will use the first value if it's available, or go to the next one, until the end of the list is reached. The default font family is defined by the browser preferences.<br />

                            In this example, the browser will try to use Source Sans Pro if it's available. If it can't find it, it will try to use Arial. If it's not available either, it will use the browser's sans-serif font.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-family type1">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-family: serif;</div>
                        </div>
                        <div className="property-define">
                            The browser will use a serif font family: all characters have stroke endings.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-family type2">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-family: sans-serif;</div>
                        </div>
                        <div className="property-define">
                            The browser will use a sans-serif font family: no character has stroke endings.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-family type3">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-family: monospace;</div>
                        </div>
                        <div className="property-define">
                            The browser will use a monospace font family: all characters have the same width.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-family type4">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-family: cursive;</div>
                        </div>
                        <div className="property-define">
                            The browser will use a cursive font family.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-family type5">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-family: fantasy;</div>
                        </div>
                        <div className="property-define">
                            The browser will use a fantasy font family.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-family type6">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={fontSizeRef}>
                <div className="name">font-size</div>
                <div className="define">Defines the size of the text.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-size: medium;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The text will use the browser's default medium size.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-size type1">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-size: 20px;</div>
                        </div>
                        <div className="property-define">
                            You can use pixel values.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-size type2">
                                    Hello world
                                    <br />
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-size: 1.2em;</div>
                        </div>
                        <div className="property-define">
                            You can use em values.<br />
                            The value is relative to the parent's font-size.<br />
                            As a result, the value will cascade if used on child elements.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-size type3">
                                    <div className="parent">
                                        Parent container:
                                        <strong>18px</strong>
                                        <div className="block block-alpha">
                                            Font-size: 1.2em =
                                            <strong> 21.6px</strong>
                                            <div className="block block-pink">
                                                Font-size: 1.2em =
                                                <strong> 25.92px</strong>
                                                <div className="block block-yellow">
                                                    Font-size: 1.2em =
                                                    <strong> 31.104px</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-size: 1.2rem;</div>
                        </div>
                        <div className="property-define">
                            You can use rem values.<br />

                            The value is relative to the root element's font-size, which is the html element.<br />
                            As a result, the value will not vary depending on the depth of the element in the HTML hierarchy, and will reamin context independent.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-size type4">
                                    <div className="parent">
                                        html root element
                                        <strong> 18px</strong>
                                        <div className="block block-alpha">
                                            Font-size: 1.2rem =
                                            <strong> 21.6px</strong>
                                            <div className="block block-pink">
                                                Font-size: 1.2rem =
                                                <strong> 21.6px</strong>
                                                <div className="block block-yellow">
                                                    Font-size: 1.2rem =
                                                    <strong> 21.6px</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-size: 90%;</div>
                        </div>
                        <div className="property-define">
                            You can use percentage values. They act like em values.<br />
                            The value is relative to the parent's font-size.<br />
                            As a result, the value will cascade if used on child elements.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-size type5">
                                    <div className="parent">
                                        Parent container:
                                        <strong> 18px</strong>
                                        <div className="block block-alpha">
                                            Font-size: 90% =
                                            <strong> 16.2x</strong>
                                            <div className="block block-pink">
                                                Font-size: 90% =
                                                <strong> 14.58px</strong>
                                                <div className="block block-yellow">
                                                    Font-size: 90% =
                                                    <strong> 13.122px</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-size: 90%;</div>
                        </div>
                        <div className="property-define">
                            You can use percentage values. They act like em values.<br />
                            The value is relative to the parent's font-size.<br />
                            As a result, the value will cascade if used on child elements.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-size type6">
                                    <div className="parent">
                                        Parent container:
                                        <strong> 18px</strong>
                                        <div className="block block-alpha">
                                            Font-size: smaller
                                            <div className="block block-pink">
                                                Font-size: smaller
                                                <div className="block block-yellow">
                                                    Font-size: smaller
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-size: x-large;</div>
                        </div>
                        <div className="property-define">
                            You can use absolute keywords. The value is relative to the root element html.<br />
                            The following are available:
                            <ul>
                                <li>xx-small</li>
                                <li>x-small</li>
                                <li>small</li>
                                <li>medium</li>
                                <li>large</li>
                                <li>x-large</li>
                                <li>xx-large</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-size type7">
                                    <div className="parent">
                                        html root element:
                                        <strong> 18px</strong>
                                        <div className="block block-alpha">
                                            Font-size: x-large
                                            <div className="block block-pink">
                                                Font-size: x-large
                                                <div className="block block-yellow">
                                                    Font-size: x-large
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={fontStyleRef}>
                <div className="name">font-style</div>
                <div className="define">Defines how much the text is slanted.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-style: normal;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The text is not slanted.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-style type1">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-style: italic;</div>
                        </div>
                        <div className="property-define">
                            Use the italic version of the font: the letters are slightly slanted.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-style type2">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-style: oblique;</div>
                        </div>
                        <div className="property-define">
                            Use the oblique version of the font: the letters are more slanted than italic.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-style type3">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={fontVariantRef}>
                <div className="name">font-variant</div>
                <div className="define">Defines which glyph to use for each letter.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-variant: normal;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Each letter uses its normal glyph.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-variant type1">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-variant: small-caps;</div>
                        </div>
                        <div className="property-define">
                            Each letter uses its small capitalized version.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-variant type2">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={fontWeightRef}>
                <div className="name">font-weight</div>
                <div className="define">Defines the weight of the text.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-weight: normal;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The text is in normal weight.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-weight type1">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-weight: bold;</div>
                        </div>
                        <div className="property-define">
                            The text becomes bold.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-weight type2">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-weight: 600;</div>
                        </div>
                        <div className="property-define">
                            You can use numeric values. They all correspond to a particular named weight:<br />
                            <ul>
                                <li>100 Thin</li>
                                <li>200 Extra Light</li>
                                <li>300 Light</li>
                                <li>400 Normal</li>
                                <li>500 Medium</li>
                                <li>600 Semi Bold</li>
                                <li>700 Bold</li>
                                <li>800 Extra Bold</li>
                                <li>900 Ultra Bold</li>
                            </ul>
                            If the font family doesn't provide the requested weight, it will use the closest available one.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-weight type3">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>font-weight: lighter;</div>
                        </div>
                        <div className="property-define">
                            You can use relative keywords: lighter or bolder. The browser will use the next available weight.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo font-weight type4">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="css-reference-content" ref={fontRef}>
                <div className="name">font</div>
                <div className="define">Shorthand property for font-style font-variant font-weight font-size line-height and font-family.</div>
            </div>

            <div className="css-reference-content" ref={letterSpacingRef}>
                <div className="name">letter-spacing</div>
                <div className="define">Defines the spacing between the characters of a block of text.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>letter-spacing: normal;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The spacing between the characters is normal.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo letter-spacing type1">
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>letter-spacing: 2px;</div>
                        </div>
                        <div className="property-define">
                            You can use pixel values.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo letter-spacing type2">
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>letter-spacing: 0.1em;</div>
                        </div>
                        <div className="property-define">
                            You can use em values: this allows the spacing to remain relative to the font-size.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo letter-spacing type3">
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={lineHeightRef}>
                <div className="name">line-height</div>
                <div className="define">Defines the height of a single line of text.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>line-height: normal;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Reverts to the default value of the browser.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo line-height type1">
                                    <div className="block block-alpha">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>line-height: normal;</div>
                            <div className="property-name-default style2">
                                recommended
                                <div className="property-name-default-hover">
                                    This type of value is recommended for this property
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Reverts to the default value of the browser.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo line-height type2">
                                    <div className="block block-alpha">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>line-height: 30px;</div>
                        </div>
                        <div className="property-define">
                            You can use pixel values.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo line-height type3">
                                    <div className="block block-alpha">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>line-height: 0.8em;</div>
                        </div>
                        <div className="property-define">
                            You can use pixel values.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo line-height type4">
                                    <div className="block block-alpha">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={textAlignRef}>
                <div className="name">text-align</div>
                <div className="define">Defines how the text content of the element is horizontally aligned.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-align: left;</div>
                        </div>
                        <div className="property-define">
                            The text content is aligned to the left.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-align type1">
                                    <div className="block block-alpha">
                                        <strong>Hello world</strong><br />
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-align: right;</div>
                        </div>
                        <div className="property-define">
                            The text content is aligned to the right.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-align type2">
                                    <div className="block block-alpha">
                                        <strong>Hello world</strong><br />
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-align: right;</div>
                        </div>
                        <div className="property-define">
                            The text content is aligned to the right.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-align type3">
                                    <div className="block block-alpha">
                                        <strong>Hello world</strong><br />
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-align: right;</div>
                        </div>
                        <div className="property-define">
                            The text content is aligned to the right.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-align type4">
                                    <div className="block block-alpha">
                                        <strong>Hello world</strong><br />
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={textDecroationRef}>
                <div className="name">text-decoration</div>
                <div className="define">Defines how the text content of the element is decorated.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-decoration: none;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Removes any text decoration.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-decoration type1">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-decoration: underline;</div>
                        </div>
                        <div className="property-define">
                            Underlines the text content.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-decoration type2">
                                    Hello world
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={textIndentRef}>
                <div className="name">text-indent</div>
                <div className="define">Defines the indentation of the element's first line of text.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-indent: 0;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The text is not indented.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-indent type1">
                                    <div className="block block-alpha">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-indent: 40px;</div>
                        </div>
                        <div className="property-define">
                            You can use numeric values like pixels, (r)em, percentages...

                            Notice how only the first line is indented.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-indent type2">
                                    <div className="block block-alpha">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-indent: -2em;</div>
                        </div>
                        <div className="property-define">
                            You can also use negative values.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-indent type3">
                                    <div className="block block-alpha">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={textOverflowRef}>
                <div className="name">text-overflow</div>
                <div className="define">Defines how the hidden text content behaves if it's overflowing.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-overflow: clip;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The text content is clipped and not accessible.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-overflow type1">
                                    <div className="block block-alpha">
                                        Hello world
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-overflow: ellipsis;</div>
                        </div>
                        <div className="property-define">
                            The overflowing content is replaced by an ellipsis: …
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-overflow type2">
                                    <div className="block block-alpha">
                                        Hello world
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={textShadowRef}>
                <div className="name">text-shadow</div>
                <div className="define">Defines the shadow of the text content.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-shadow: none;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The text content has no shadow.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-shadow type1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-shadow: 2px 6px;</div>
                        </div>
                        <div className="property-define">
                            You need at least two values:
                            <ul>
                                <li>the first is the horizontal offset</li>
                                <li>the second is the vertical offset</li>
                            </ul>
                            The shadow color will be inherited from the text color.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-shadow type2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-shadow: 2px 6px red;</div>
                        </div>
                        <div className="property-define">
                            You can define a color as the last value.<br />
                            As with color, you can use color names, hexadecimal, rgb, hsl...
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-shadow type3">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-shadow: 2px 4px 10px red;</div>
                        </div>
                        <div className="property-define">
                            The optional third value defines the blur of the shadow.<br />
                            The color will be diffused across 10px in this example, from opaque to transparent.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-shadow type4">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={textTransformRef}>
                <div className="name">text-transform</div>
                <div className="define">Defines how the text content should be transformed.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-transform: none;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Removes any text transformation: the text will appear the same as in the HTML code.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-transform type1">
                                    <strong>Hello world!</strong><br />
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-transform: capitalize;</div>
                        </div>
                        <div className="property-define">
                            Turns the first letter of each word into a capital letter.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-transform type2">
                                    <strong>Hello world!</strong><br />
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-transform: uppercase;</div>
                        </div>
                        <div className="property-define">
                            Turns all letters into capital letters.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-transform type3">
                                    <strong>Hello world!</strong><br />
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-transform: lowercase;</div>
                        </div>
                        <div className="property-define">
                            Turns all letters into lowercase letters.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo text-transform type4">
                                    <strong>Hello world!</strong><br />
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={whiteSpaceRef}>
                <div className="name">white-space</div>
                <div className="define">Defines how the element's white space is handled.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>text-transform: none;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Sequences of spaces are combined into one.<br />
                            Line breaks are ignored.<br />
                            The text content is wrapped.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo white-space type1">
                                    #parent .selector ul li blockquote {`{color: red; font-size: 1rem;}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>white-space: nowrap;</div>
                        </div>
                        <div className="property-define">
                            Sequences of spaces are combined into one.<br />
                            Line breaks are ignored.<br />
                            The text content is not wrapped and remains on a single line.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo white-space type2">
                                    #parent .selector ul li blockquote {`{color: red; font-size: 1rem;}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>white-space: pre;</div>
                        </div>
                        <div className="property-define">
                            The white space is exactly preserved.
                            <ul>
                                <li>Sequences of spaces are preserved</li>
                                <li>Lines break only on new lines and {`<br>`}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo white-space type3">
                                    #parent .selector ul li blockquote {`{color: red; font-size: 1rem;}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>white-space: pre-wrap;</div>
                        </div>
                        <div className="property-define">
                            The white space is mostly preserved.
                            <ul>
                                <li>Sequences of spaces are preserved</li>
                                <li>Lines break on new lines, {`<br>`}, but also when reaching the end of the element</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo white-space type4">
                                    #parent .selector ul li blockquote {`{color: red; font-size: 1rem;}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>white-space: pre-line;</div>
                        </div>
                        <div className="property-define">
                            Only new lines are preserved.
                            <ul>
                                <li>Sequences of spaces are combined into one</li>
                                <li>Lines break on new lines, {`<br>`}, but also when reaching the end of the element</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo white-space type5">
                                    #parent .selector ul li blockquote {`{color: red; font-size: 1rem;}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={wordBreakRef}>
                <div className="name">word-break</div>
                <div className="define">Defines how words should break when reaching the end of a line.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>word-break: normal;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Words with no space will not break. Sequences of uninterrupted characters will be displayed on a single line.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo word-break type1">
                                    Thequickbrownfoxjumpsoverthelazydog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>word-break: break-all;</div>
                        </div>
                        <div className="property-define">
                            Words with no space will break as soon as they reach the end of a line.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo word-break type2">
                                    Thequickbrownfoxjumpsoverthelazydog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={wordSpacingRef}>
                <div className="name">word-spacing</div>
                <div className="define">Defines the spacing between words of a block of text.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>word-spacing: normal;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The spacing between the characters is normal.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo word-spacing type1">
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>word-spacing: 5px;</div>
                        </div>
                        <div className="property-define">
                            You can use pixel values.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo word-spacing type2">
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>word-spacing: 2em;</div>
                        </div>
                        <div className="property-define">
                            You can use em values: this allows the spacing to remain relative to the font-size.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output typo word-spacing type3">
                                    The quick brown fox jumps over the lazy dog
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Typography;