import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defaultSet, changeInput, convert } from '../store/modules/pxtoem';
import Pxtoem from '../components/pxtoem';
import { isNumeric } from '../components/utils/Regix';
import { btnFocus } from '../components/animation/BtnFocus';

const PxtoemContainer = () => {
  const dispatch = useDispatch();
  const { defaultValue, input, value } = useSelector(({ pxtoem }) => ({
    defaultValue: pxtoem.defaultValue,
    input: pxtoem.input,
    value: pxtoem.value,
  }));

  const handleChange = e => {
    const { value } = e.target;
    const key = e.target.getAttribute('id');

		dispatch(changeInput({ key: key, value: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    // 숫자인지 테스트
    let numCheck = isNumeric(input.value);
    if ( numCheck === false ) {
      alert('숫자만 입력해주세요!');
      return false;
    }
    
    const pxToEmSet = function (val) {
      let result = val / defaultValue;
      result = result.toFixed(2);

      return result;
    }

    const emToPxSet = function (val) {
      let result = val * defaultValue;

      return result;
    }

    let result = '';
    if ( input.key == 'pxToEm' ) {
      result = pxToEmSet(input.value);
      result += ' em';
    } else if ( input.key == 'emToPx' ) {
      result = emToPxSet(input.value);
      result += ' px';
    }

    // btn focus
    btnFocus(e.target);

    dispatch(convert(result));
  };
  
  const handleDefaultChange = e => {
    const { value } = e.target;

    dispatch(defaultSet(value));
  }

  return (
    <Pxtoem input={input} value={value} onDefaultSet={handleDefaultChange} onChange={handleChange} onSubmit={handleSubmit} />
  );
};

export default PxtoemContainer;