import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

const TITLE = 'CSS Reference - Box-model';

const Boxmodel = ({ input, value, onChange, onPickerChange, onSubmit }) => {

  const bottomWidthRef = useRef(null);
  const leftWidthRef = useRef(null);
  const rightWidthRef = useRef(null);
  const topWidthRef = useRef(null);
  const borderWidthRef = useRef(null);
  const boxSizingRef = useRef(null);
  const heightRef = useRef(null);
  const lineHeightRef = useRef(null);
  const marginBottomRef = useRef(null);
  const marginleftRef = useRef(null);
  const marginRightRef = useRef(null);
  const marginTopRef = useRef(null);
  const marginRef = useRef(null);
  const maxHeightRef = useRef(null);
  const maxWidthRef = useRef(null);
  const minHeightRef = useRef(null);
  const minWidthRef = useRef(null);
  const paddingBottomRef = useRef(null);
  const paddingLeftRef = useRef(null);
  const paddingRightRef = useRef(null);
  const paddingTopRef = useRef(null);
  const paddingRef = useRef(null);
  const widthRef = useRef(null);


  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // 클릭 시 복사
  const clickCopyText = (event) => {
    const textToCopy = event.target.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      event.target.classList.add('copied');
      setTimeout(() => {
        event.target.classList.remove('copied');
      }, 1000);
    }).catch(err => {
      console.error('텍스트 복사 실패', err);
    });
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="top-title css">
        <h2>CSS Reference</h2>
        <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
      </div>

      <div className="menu-wrap">
        <div className="menu-type css">
          <ul>
            <Link to="/css-ref"><li>All</li></Link>
            <Link to="/css-ref/animations"><li>Animations</li></Link>
            <Link to="/css-ref/backgrounds"><li>Backgrounds</li></Link>
            <Link to="/css-ref/boxmodel"><li className='checked'>Box model</li></Link>
            <Link to="/css-ref/flexbox"><li>Flexbox</li></Link>
            <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
            <Link to="/css-ref/positioning"><li>Positioning</li></Link>
            <Link to="/css-ref/transitions"><li>Transitions</li></Link>
            <Link to="/css-ref/typography"><li>Typography</li></Link>
          </ul>
        </div>

        <div className="menu-type css category">
          <ul>
            <li onClick={() => scrollToRef(bottomWidthRef)}>border-bottom-width</li>
            <li onClick={() => scrollToRef(leftWidthRef)}>border-left-width</li>
            <li onClick={() => scrollToRef(rightWidthRef)}>border-right-width</li>
            <li onClick={() => scrollToRef(topWidthRef)}>border-top-width</li>
            <li onClick={() => scrollToRef(borderWidthRef)}>border-width</li>
            <li onClick={() => scrollToRef(boxSizingRef)}>box-sizing</li>
            <li onClick={() => scrollToRef(heightRef)}>height</li>
            <li onClick={() => scrollToRef(lineHeightRef)}>line-height</li>
            <li onClick={() => scrollToRef(marginBottomRef)}>margin-bottom</li>
            <li onClick={() => scrollToRef(marginleftRef)}>margin-left</li>
            <li onClick={() => scrollToRef(marginRightRef)}>margin-right</li>
            <li onClick={() => scrollToRef(marginTopRef)}>margin-top</li>
            <li onClick={() => scrollToRef(marginRef)}>margin</li>
            <li onClick={() => scrollToRef(maxHeightRef)}>max-height</li>
            <li onClick={() => scrollToRef(maxWidthRef)}>max-width</li>
            <li onClick={() => scrollToRef(minHeightRef)}>min-height</li>
            <li onClick={() => scrollToRef(minWidthRef)}>min-width</li>
            <li onClick={() => scrollToRef(paddingBottomRef)}>padding-bottom</li>
            <li onClick={() => scrollToRef(paddingLeftRef)}>padding-left</li>
            <li onClick={() => scrollToRef(paddingRightRef)}>padding-right</li>
            <li onClick={() => scrollToRef(paddingTopRef)}>padding-top</li>
            <li onClick={() => scrollToRef(paddingRef)}>padding</li>
            <li onClick={() => scrollToRef(widthRef)}>width</li>
          </ul>
        </div>
      </div>

      {/* border-bottom-width */}
      <div className="css-reference-content-wrap" ref={bottomWidthRef}>
        <div className="css-reference-content">
          <div className="name">border-bottom-width</div>
          <div className="define">Like border-width, but for the bottom border only.</div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name-wrap">
                <div className="property-name" onClick={clickCopyText}>border-bottom-width: 0;</div>
                <div className="property-name-default">
                  default
                  <div className="property-name-default-hover">
                    This is the property's default value
                  </div>
                </div>
              </div>
              <div className="property-define">
                Removes the bottom border.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output">
                    <div className="border bottom-width type1">Hello world</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contents-tab">
            <div className="contents-title">
              <div className="property-name" onClick={clickCopyText}>border-bottom-width: 4px;</div>
              <div className="property-define">
                You can use pixel values.
              </div>
            </div>

            <div className="contents-example">
              <div className="contents-box-top"></div>
              <div className="contents-box-output">
                <div className="contents-output">
                  <div className="example-background-output">
                    <div className="border bottom-width type2">Hello world</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* border-left-width */}
      <div className="css-reference-content" ref={leftWidthRef}>
        <div className="name">border-left-width</div>
        <div className="define">Like border-width, but for the left border only.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>border-left-width: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes the left border.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="border left-width type1">Hello world</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name" onClick={clickCopyText}>border-left-width: 4px;</div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="border left-width type2">Hello world</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* border-right-width */}
      <div className="css-reference-content" ref={rightWidthRef}>
        <div className="name">border-right-width</div>
        <div className="define">Like border-width, but for the right border only.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>border-right-width: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes the right border.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="border right-width type1">Hello world</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name" onClick={clickCopyText}>border-right-width: 4px;</div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="border right-width type2">Hello world</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* border-right-width */}
      <div className="css-reference-content" ref={topWidthRef}>
        <div className="name">border-top-width</div>
        <div className="define">Like border-width, but for the top border only.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>border-top-width: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes the top border.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="border top-width type1">Hello world</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name" onClick={clickCopyText}>border-top-width: 4px;</div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="border top-width type2">Hello world</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* box-sizing */}
      <div className="css-reference-content" ref={boxSizingRef}>
        <div className="name">box-sizing</div>
        <div className="define">
          Defines how the width and height of the element are calculated: whether they include the padding and borders or not.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>box-sizing: content-box;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              The width and height of the element only apply to the content of the element.<br />
              For example, this element has
              <ul>
                <li>border-width: 12px</li>
                <li>padding: 30px</li>
                <li>width: 200px</li>
              </ul>
              The full width is 24px + 60px + 200px = 284px.<br />
              The content has the defined width. The box accomodates for those dimensions.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="box-sizing type1">
                    <div className="actual">
                      <span>Hello world</span>
                      <div className="box-red"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name" onClick={clickCopyText}>box-sizing: border-box;</div>
            <div className="property-define">
              The width and height of the element apply to all parts of the element: the content, the padding and the borders.<br />
              For example, this element has
              <ul>
                <li>border-width: 12px</li>
                <li>padding: 30px</li>
                <li>width: 200px</li>
              </ul>
              The full width is 200px, no matter what.<br />
              The box has the defined width. The content accomodates for those dimensions, and ends up being 200px - 60px - 24px = 116px.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="box-sizing type2">
                    <div className="actual">
                      <span>Hello world</span>
                      <div className="box-red"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* height */}
      <div className="css-reference-content" ref={heightRef}>
        <div className="name">height</div>
        <div className="define">Defines the height of the element.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>height: auto;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              The element will automatically adjust its height to allow its content to be displayed correctly.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output height">
                  <div className="height type1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>height: 100px;</div>
            </div>
            <div className="property-define">
              You can use numeric values like pixels, (r)em, percentages...<br />
              If the content does not fit within the specified height, it will overflow. How the container will handle this overflowing content is defined by the overflow property.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output height">
                  <div className="height type2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* line-height */}
      <div className="css-reference-content" ref={lineHeightRef}>
        <div className="name">line-height</div>
        <div className="define">Defines the height of a single line of text.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>line-height: normal;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Reverts to the default value of the browser.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="line-height type1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>line-height: 1.6;</div>
              <div className="property-name-default style2">
                recommended
                <div className="property-name-default-hover">
                  This type of value is recommended for this property
                </div>
              </div>
            </div>
            <div className="property-define">
              You can use unitless values: the line height will be relative to the font size.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="line-height type2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>line-height: 30px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="line-height type3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>line-height: 0.8em;</div>
            </div>
            <div className="property-define">
              You can use em values: like with unitless values, the line height will be relative to the font size.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="line-height type4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* margin-bottom */}
      <div className="css-reference-content" ref={marginBottomRef}>
        <div className="name">margin-bottom</div>
        <div className="define">
          Defines the space outside the element, on the bottom side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-bottom: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any margin at the bottom.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin bottom type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-bottom: 30px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin bottom type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-bottom: 2em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin bottom type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-bottom: 10%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin bottom type4">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum percent"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* margin-left */}
      <div className="css-reference-content" ref={marginleftRef}>
        <div className="name">margin-left</div>
        <div className="define">
          Defines the space outside the element, on the left side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-left: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any margin on the left.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin left type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-left: 50px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin left type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-left: 7em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin left type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-left: 30%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin left type4">
                    <div className="m-position">
                      <div className="block block-pink">First item</div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="target block-plum percent"></div>
                      <div className="block block-yellow">Third item</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-left: auto;</div>
            </div>
            <div className="property-define">
              The auto keyword will give the left side a share of the remaining space.<br />
              When combined with margin-right: auto, it will center the element, if a fixed width is defined.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin left type5">
                    <div className="m-position">
                      <div className="block block-pink">First item</div>
                      <div className="block block-alpha">
                        <strong>
                          Target
                          <p>width: 200px</p>
                        </strong>
                      </div>
                      <div className="target block-plum percent"></div>
                      <div className="block block-yellow">Third item</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* margin-right */}
      <div className="css-reference-content" ref={marginRightRef}>
        <div className="name">margin-right</div>
        <div className="define">
          Defines the space outside the element, on the right side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-right: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any margin on the right.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin right type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-right: 50px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin right type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-right: 7em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin right type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-right: 30%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin right type4">
                    <div className="m-position">
                      <div className="block block-pink">First item</div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="target block-plum percent"></div>
                      <div className="block block-yellow">Third item</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-right: auto;</div>
            </div>
            <div className="property-define">
              The auto keyword will give the right side a share of the remaining space.<br />
              When combined with margin-left: auto, it will center the element, if a fixed width is defined.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin right type5">
                    <div className="m-position">
                      <div className="block block-pink">First item</div>
                      <div className="block block-alpha">
                        <strong>
                          Target
                          <p>width: 200px</p>
                        </strong>
                      </div>
                      <div className="target block-plum percent"></div>
                      <div className="block block-yellow">Third item</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* margin-top */}
      <div className="css-reference-content" ref={marginTopRef}>
        <div className="name">margin-top</div>
        <div className="define">
          Defines the space outside the element, on the top side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-top: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any margin at the top.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin top type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-top: 30px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin top type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-top: 2em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin top type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin-top: 10%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin top type4">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum percent"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* margin */}
      <div className="css-reference-content" ref={marginRef}>
        <div className="name">margin</div>
        <div className="define">
          Shorthand property for margin-top margin-right margin-bottom and margin-left.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes all margins.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin basic type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin: 30px;</div>
            </div>
            <div className="property-define">
              Removes all margins.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin basic type2">
                    <div className="block block-pink">First item</div>

                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin: 30px 60px;</div>
            </div>
            <div className="property-define">
              When using 2 values:
              <ul>
                <li>the first value is for top/bottom</li>
                <li>the second value is for right/left</li>
              </ul>
              To remember the order think about the values you haven't defined.<br />

              If you enter 2 values (top/right), you omit setting bottom and left.<br />
              Because bottom is the vertical counterpart of top, it will use top’s value.<br />
              And because left is the horizontal counterpart of right, it will use right’s value.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin basic type3">
                    <div className="block block-pink">First item</div>

                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin: 30px 60px 45px;</div>
            </div>
            <div className="property-define">
              When using 3 values:
              <ul>
                <li>the first value is for top</li>
                <li>the second value is for right/left</li>
                <li>the third value is for bottom</li>
              </ul>
              To remember the order think about the values you haven't defined.<br />
              If you enter 3 values (top/right/bottom), you omit setting left. As right’s counterpart, it will use its value.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin basic type4">
                    <div className="block block-pink">First item</div>

                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>margin: 30px 60px 45px 85px;</div>
            </div>
            <div className="property-define">
              When using 4 values:
              <ul>
                <li>the first value is for top</li>
                <li>the second value is for right</li>
                <li>the third value is for bottom</li>
                <li>the second value is for left</li>
              </ul>
              To remember the order, start at the top and go clockwise.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="margin basic type5">
                    <div className="block block-pink">First item</div>

                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* max-height */}
      <div className="css-reference-content" ref={maxHeightRef}>
        <div className="name">max-height</div>
        <div className="define">Defines the maximum height the element can be.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>max-height: none;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              The element has no limit in terms of height.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output height">
                  <div className="max-height type1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>max-height: 2000px;</div>
            </div>
            <div className="property-define">
              You can use numeric values like pixels, (r)em, percentages...<br />
              If the maximum height is larger than the element's actual height, the max height has no effect.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output height">
                  <div className="max-height type2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>max-height: 100px;</div>
            </div>
            <div className="property-define">
              If the content does not fit within the maximum height, it will overflow. How the container will handle this overflowing content is defined by the overflow property.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output height">
                  <div className="max-height type3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* max-width */}
      <div className="css-reference-content" ref={maxWidthRef}>
        <div className="name">max-width</div>
        <div className="define">Defines the maximum width the element can be.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>max-width: none;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              The element has no limit in terms of width.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="max-width type1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>max-width: 2000px;</div>
            </div>
            <div className="property-define">
              You can use numeric values like pixels, (r)em, percentages...<br />
              If the maximum width is larger than the element's actual width, the max width has no effect.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="max-width type2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>max-width: 150px;</div>
            </div>
            <div className="property-define">
              If the content does not fit within the maximum width, it will automatically change the height of the element to accomodate for the wrapping of the content.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="max-width type3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* min-height */}
      <div className="css-reference-content" ref={minHeightRef}>
        <div className="name">min-height</div>
        <div className="define">Defines the minimum height of the element.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>min-height: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              The element has no minimum height.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="min-height type1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>min-height: 250px;</div>
            </div>
            <div className="property-define">
              You can use numeric values like pixels, (r)em, percentages...<br />
              If the minimum height is larger than the element's actual height, the min height will be applied.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="min-height type2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>min-height: 5px;</div>
            </div>
            <div className="property-define">
              If the minimum height is smaller than the element's actual height, the min height has no effect.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="min-height type3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet. Pellentesque interdum, nisl nec interdum maximus, augue diam porttitor lorem, et sollicitudin felis neque sit amet erat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* min-width */}
      <div className="css-reference-content" ref={minWidthRef}>
        <div className="name">min-width</div>
        <div className="define">Defines the minimum width of the element.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>min-width: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              The element has no minimum width.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="min-width type1">
                    Hello world
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>min-width: 300px;</div>
            </div>
            <div className="property-define">
              You can use numeric values like pixels, (r)em, percentages...<br />
              If the minimum width is larger than the element's actual width, the min width will be applied.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="min-width type2">
                    Hello world
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>min-width: 5px;</div>
            </div>
            <div className="property-define">
              If the minimum width is smaller than the element's actual width, the min width has no effect.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="min-width type3">
                    Hello world
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* padding-bottom */}
      <div className="css-reference-content" ref={paddingBottomRef}>
        <div className="name">padding-bottom</div>
        <div className="define">
          Defines the space inside the element, on the bottom side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-bottom: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any padding on the bottom.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding bottom type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-bottom: 50px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding bottom type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-bottom: 7em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding bottom type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-bottom: 30%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding bottom type4">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum percent"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* padding-left */}
      <div className="css-reference-content" ref={paddingLeftRef}>
        <div className="name">padding-left</div>
        <div className="define">
          Defines the space inside the element, on the left side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-left: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any padding on the left.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding left type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-left: 50px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding left type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-left: 7em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding left type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-left: 30%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding left type4">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum percent"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* padding-right */}
      <div className="css-reference-content" ref={paddingRightRef}>
        <div className="name">padding-right</div>
        <div className="define">
          Defines the space inside the element, on the right side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-right: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any padding on the right.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding right type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-right: 50px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding right type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-right: 7em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding right type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-right: 30%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding right type4">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum percent"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* padding-top */}
      <div className="css-reference-content" ref={paddingTopRef}>
        <div className="name">padding-top</div>
        <div className="define">
          Defines the space inside the element, on the top side.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-top: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              Removes any padding on the top.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding top type1">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-top: 50px;</div>
            </div>
            <div className="property-define">
              You can use pixel values.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding top type2">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-top: 7em;</div>
            </div>
            <div className="property-define">
              You can use (r)em values.<br />
              The value is relative to the font size:
              <ul>
                <li>em: relative to the element's current font size</li>
                <li>rem: relative to html the root element's font size</li>
              </ul>
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding top type3">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding-top: 30%;</div>
            </div>
            <div className="property-define">
              You can use percentage values.<br />
              The percentage is based on the width of the element.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="padding top type4">
                    <div className="block block-pink">First item</div>
                    <div className="block block-alpha">
                      <strong>Target</strong>
                      <div className="target block-plum percent"></div>
                    </div>
                    <div className="block block-yellow">Third item</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* padding */}
      <div className="css-reference-content" ref={paddingRef}>
        <div className="name">padding</div>
        <div className="define">
          Shorthand property for padding-top padding-right padding-bottom and padding-left.
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding: 0;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              When using 1 value, the padding is set for all 4 sides.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output p-basic">
                  <div className="padding basic type1">
                    <div className="block block-alpha">
                      <strong>Target</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding: 30px;</div>
            </div>
            <div className="property-define">
              When using 1 value, the padding is set for all 4 sides.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output p-basic">
                  <div className="padding basic type1">
                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding: 30px 60px;</div>
            </div>
            <div className="property-define">
              When using 2 values:
              <ul>
                <li>  the first value is for top/bottom</li>
                <li>the second value is for right/left</li>
              </ul>
              To remember the order think about the values you haven't defined.<br />
              If you enter 2 values (top/right), you omit setting bottom and left.<br /> Because bottom is the vertical counterpart of top, it will use top’s value. And because left is the horizontal counterpart of right, it will use right’s value.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output p-basic">
                  <div className="padding basic type3">
                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding: 30px 60px 45px;</div>
            </div>
            <div className="property-define">
              When using 3 values:
              <ul>
                <li> the first value is for top</li>
                <li>the second value is for right/left</li>
                <li>the third value is for bottom</li>
              </ul>
              To remember the order think about the values you haven't defined.<br />
              If you enter 3 values (top/right/bottom), you omit setting left. As right’s counterpart, it will use its value.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output p-basic">
                  <div className="padding basic type4">
                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>padding: 30px 60px 45px 85px;</div>
            </div>
            <div className="property-define">
              When using 4 values:
              <ul>
                <li>the first value is for top</li>
                <li>the second value is for right</li>
                <li>the third value is for bottom</li>
                <li>the fourth value is for left</li>
              </ul>
              To remember the order, start at the top and go clockwise.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output p-basic">
                  <div className="padding basic type5">
                    <div className="square square-top"></div>
                    <div className="container">
                      <div className="square square-left"></div>
                      <div className="block block-alpha">
                        <strong>Target</strong>
                      </div>
                      <div className="square square-right"></div>
                    </div>
                    <div className="square square-bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* width */}
      <div className="css-reference-content" ref={widthRef}>
        <div className="name">width</div>
        <div className="define">Defines the width of the element.</div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>width: auto;</div>
              <div className="property-name-default">
                default
                <div className="property-name-default-hover">
                  This is the property's default value
                </div>
              </div>
            </div>
            <div className="property-define">
              The element will automatically adjust its width to allow its content to be displayed correctly.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="width type1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>width: 240px;</div>
            </div>
            <div className="property-define">
              You can use numeric values like pixels, (r)em, percentages...
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="width type2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contents-tab">
          <div className="contents-title">
            <div className="property-name-wrap">
              <div className="property-name" onClick={clickCopyText}>width: 50%;</div>
            </div>
            <div className="property-define">
              If you use percentages, the value is relative to the container's width.
            </div>
          </div>

          <div className="contents-example">
            <div className="contents-box-top"></div>
            <div className="contents-box-output">
              <div className="contents-output">
                <div className="example-background-output">
                  <div className="width type3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut. Maecenas imperdiet felis nisi, fringilla luctus felis hendrerit sit amet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Boxmodel;