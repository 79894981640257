import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useState } from 'react';

const TITLE = 'CSS Reference - Flexbox';

const Flexbox = ({ input, value, onChange, onPickerChange, onSubmit }) => {


    // 서브 카테고리 연결
    const alignContentRef = useRef(null);
    const alignItemsRef = useRef(null);
    const alignSelfRef = useRef(null);
    const flexBasisRef = useRef(null);
    const flexDirectionRef = useRef(null);
    const flexFlowRef = useRef(null);
    const flexGrowRef = useRef(null);
    const flexShrinkRef = useRef(null);
    const flexWrapRef = useRef(null);
    const justifyContentRef = useRef(null);
    const orderRef = useRef(null);

    const scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // 클릭 시 복사
    const clickCopyText = (event) => {
        const textToCopy = event.target.innerText;
        navigator.clipboard.writeText(textToCopy).then(() => {
            event.target.classList.add('copied');
            setTimeout(() => {
                event.target.classList.remove('copied');
            }, 1000);
        }).catch(err => {
            console.error('텍스트 복사 실패', err);
        });
    }

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
                <meta name="description" content="" />
            </Helmet>

            <div className="top-title css">
                <h2>CSS Reference</h2>
                <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
            </div>

            <div className="menu-wrap">
                <div className="menu-type css">
                    <ul>
                        <Link to="/css-ref"><li>All</li></Link>
                        <Link to="/css-ref/animations"><li>Animations</li></Link>
                        <Link to="/css-ref/backgrounds"><li>Backgrounds</li></Link>
                        <Link to="/css-ref/boxmodel"><li>Box model</li></Link>
                        <Link to="/css-ref/flexbox"><li className='checked'>Flexbox</li></Link>
                        <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
                        <Link to="/css-ref/positioning"><li>Positioning</li></Link>
                        <Link to="/css-ref/transitions"><li>Transitions</li></Link>
                        <Link to="/css-ref/typography"><li>Typography</li></Link>
                    </ul>
                </div>

                <div className="menu-type css category">
                    <ul>
                        <li onClick={() => scrollToRef(alignContentRef)}>align-content</li>
                        <li onClick={() => scrollToRef(alignItemsRef)}>align-items</li>
                        <li onClick={() => scrollToRef(alignSelfRef)}>align-self</li>
                        <li onClick={() => scrollToRef(flexBasisRef)}>flex-basis</li>
                        <li onClick={() => scrollToRef(flexDirectionRef)}>flex-direction</li>
                        <li onClick={() => scrollToRef(flexFlowRef)}>flex-flow</li>
                        <li onClick={() => scrollToRef(flexGrowRef)}>flex-grow</li>
                        <li onClick={() => scrollToRef(flexShrinkRef)}>flex-shrink</li>
                        <li onClick={() => scrollToRef(flexWrapRef)}>flex-wrap</li>
                        <li onClick={() => scrollToRef(justifyContentRef)}>justify-content</li>
                        <li onClick={() => scrollToRef(orderRef)}>order</li>
                    </ul>
                </div>
            </div>

            <div className="css-reference-content" ref={alignContentRef}>
                <div className="name">align-content</div>
                <div className="define">Defines how each line is aligned within a flexbox/grid container. It only applies if flex-wrap: wrap is present, and if there are multiple lines of flexbox/grid items.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-content: stretch;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            Each line will stretch to fill the remaining space.<br />
                            In this case, the container is 300px high. All boxes are 50px high, apart from the second one who is 100px high.
                            <ul>
                                <li>The first line is 100px high</li>
                                <li>The second line is 50px high</li>
                                <li>The remaing space is 150px</li>
                            </ul>
                            This remaining space is distributed equally amongst the two lines:
                            <ul>
                                <li>The first line is now 175px high</li>
                                <li>The second line is now 125px high</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align content">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>

                                    <div className="box box-red"></div>
                                    <div className="box box-green"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-content: flex-start;</div>
                        </div>
                        <div className="property-define">
                            Each line will only fill the space it needs. They will all move towards the start of the flexbox/grid container's cross axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align content type2">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>

                                    <div className="box box-red"></div>
                                    <div className="box box-green"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-content: flex-end;</div>
                        </div>
                        <div className="property-define">
                            Each line will only fill the space it needs. They will all move towards the end of the flexbox/grid container's cross axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align content type3">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>

                                    <div className="box box-red"></div>
                                    <div className="box box-green"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-content: center;</div>
                        </div>
                        <div className="property-define">
                            Each line will only fill the space it needs. They will all move towards the center of the flexbox/grid container's cross axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align content type4">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>

                                    <div className="box box-red"></div>
                                    <div className="box box-green"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-content: space-between;</div>
                        </div>
                        <div className="property-define">
                            Each line will only fill the space it needs. The remaining space will appear between the lines.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align content type5">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>

                                    <div className="box box-red"></div>
                                    <div className="box box-green"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-content: space-around;</div>
                        </div>
                        <div className="property-define">
                            Each line will only fill the space it needs. The remaining space will be distributed equally around the lines: before the first line, between the two, and after the last one.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align content type6">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>

                                    <div className="box box-red"></div>
                                    <div className="box box-green"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={alignItemsRef}>
                <div className="name">align-items</div>
                <div className="define">Defines how flexbox items are aligned according to the cross axis, within a line of a flexbox container.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-items: flex-start;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The flexbox items are aligned at the start of the cross axis.<br />
                            By default, the cross axis is vertical. This means the flexbox items will be aligned vertically at the top.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align items">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-items: flex-end;</div>

                        </div>
                        <div className="property-define">
                            The flexbox items are aligned at the end of the cross axis.<br />
                            By default, the cross axis is vertical. This means the flexbox items will be aligned vertically at the bottom.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align items flex-end">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-items: center;</div>

                        </div>
                        <div className="property-define">
                            The flexbox items are aligned at the center of the cross axis.<br />
                            By default, the cross axis is vertical. This means the flexbox items will be centered vertically.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align items center">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-items: baseline;</div>

                        </div>
                        <div className="property-define">
                            The flexbox items are aligned at the baseline of the cross axis.<br />
                            By default, the cross axis is vertical. This means the flexbox items will align themselves in order to have the baseline of their text align along a horizontal line.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align items baseline">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-items: stretch;</div>

                        </div>
                        <div className="property-define">
                            The flexbox items will stretch across the whole cross axis.<br />
                            By default, the cross axis is vertical. This means the flexbox items will fill up the whole vertical space.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align items stretch">
                                    <p className="square square-plum">1</p>
                                    <p className="square square-plum">2</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={alignSelfRef}>
                <div className="name">align-self</div>
                <div className="define">Works like align-items, but applies only to a single flexbox item, instead of all of them.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-self: auto;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The target will use the value of align-items.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align self">
                                    <p className="square square-plum">1</p>
                                    <p className="square target">Target</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-default"></div>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-self: flex-start;</div>
                        </div>
                        <div className="property-define">
                            If the container has align-items: center and the target has align-self: flex-start, only the target will be at the start of the cross axis.<br />
                            By default, this means it will be aligned vertically at the top.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align self flex-start">
                                    <p className="square square-plum">1</p>
                                    <p className="square target">Target</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-default"></div>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-self: flex-end;</div>
                        </div>
                        <div className="property-define">
                            If the container has align-items: center and the target has align-self: flex-end, only the target will be at the end of the cross axis.<br />

                            By default, this means it will be aligned vertically at the bottom.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align self flex-end">
                                    <p className="square square-plum">1</p>
                                    <p className="square target">Target</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-default"></div>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-self: center;</div>
                        </div>
                        <div className="property-define">
                            If the container has align-items: flex-start and the target has align-self: center, only the target will be at the center of the cross axis.<br />

                            By default, this means it will be vertically centered.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align self center">
                                    <p className="square square-plum">1</p>
                                    <p className="square target">Target</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-default"></div>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-self: baseline;</div>
                        </div>
                        <div className="property-define">
                            If the container has align-items: center and the target has align-self: baseline, only the target will be at the baseline of the cross axis.<br />

                            By default, this means it will be aligned along the basline of the text.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align self baseline">
                                    <p className="square square-plum">1</p>
                                    <p className="square target">Target</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-default"></div>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>align-self: stretch;</div>
                        </div>
                        <div className="property-define">
                            If the container has align-items: center and the target has align-self: stretch, only the target will stretch along the whole cross axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output align self stretch">
                                    <p className="square square-plum">1</p>
                                    <p className="square target">Target</p>
                                    <p className="square square-plum">3</p>
                                    <p className="square square-plum">4</p>
                                    <p className="square square-plum">5</p>
                                    <div className="line line-default"></div>
                                    <div className="line line-red"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={flexBasisRef}>
                <div className="name">flex-basis</div>
                <div className="define">Defines the initial size of a flexbox item.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-basis: auto;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will be automatically sized based on its content, or on any height or width value if they are defined.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex basis">
                                    <p class="block block-alpha">Flexbox item</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-basis: 80px;</div>
                        </div>
                        <div className="property-define">
                            You can define pixel or (r)em values. The element will wrap its content to avoid any overflow.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex basis type2">
                                    <p class="block block-alpha">Flexbox item</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={flexDirectionRef}>
                <div className="name">flex-direction</div>
                <div className="define">Defines how flexbox items are ordered within a flexbox container.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-direction: row;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The flexbox items are ordered the same way as the text direction, along the main axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex direction row">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                    <p class="block block-yellow"><strong>4.</strong> Four</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-direction: row-reverse;</div>
                        </div>
                        <div className="property-define">
                            The flexbox items are ordered the opposite way as the text direction, along the main axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex direction row-reverse">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                    <p class="block block-yellow"><strong>4.</strong> Four</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-direction: column;</div>
                        </div>
                        <div className="property-define">
                            The flexbox items are ordered the same way as the text direction, along the cross axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex direction column">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                    <p class="block block-yellow"><strong>4.</strong> Four</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-direction: column-reverse;</div>
                        </div>
                        <div className="property-define">
                            The flexbox items are ordered the opposite way as the text direction, along the cross axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex direction column-reverse">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                    <p class="block block-yellow"><strong>4.</strong> Four</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={flexFlowRef}>
                <div className="name">flex-flow</div>
                <div className="define">Shorthand property for flex-direction and flex-wrap.</div>
            </div>

            <div className="css-reference-content" ref={flexGrowRef}>
                <div className="name">flex-frow</div>
                <div className="define">Defines how much a flexbox item should grow if there's space available.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-grow: 0;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will not grow if there's space available. It will only use the space it needs.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex grow">
                                    <p class="block block-alpha"><strong>Target</strong></p>
                                    <p class="block block-beta">Item</p>
                                    <p class="block block-pink">Item</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-grow: 1;</div>
                        </div>
                        <div className="property-define">
                            The element will grow by a factor of 1. It will fill up the remaining space if no other flexbox item has a flex-grow value.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex grow type2">
                                    <p class="block block-alpha"><strong>Target</strong></p>
                                    <p class="block block-pink">Item</p>
                                    <p class="block block-yellow">Item</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-grow: 2;</div>
                        </div>
                        <div className="property-define">
                            TBecause the flex-grow value is relative, its behaviour depends on the value of the flexbox item siblings.<br />

                            In this example, the remaining space is divided in 3:
                            <ul>
                                <li>1 third goes to the green item</li>
                                <li>2 thirds go to the pink item</li>
                                <li>Nothing goes to the yellow item, who retains its initial width</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex grow type3">
                                    <p class="block block-alpha">Item<br />
                                        <strong>1</strong></p>
                                    <p class="block block-pink">Item<br />
                                        <strong>2</strong></p>
                                    <p class="block block-yellow">Item<br />
                                        <strong>0</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={flexShrinkRef}>
                <div className="name">flex-shrink</div>
                <div className="define">Defines how much a flexbox item should shrink if there's not enough space available.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-shrink: 1;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            If there's not enough space available in the container's main axis, the element will shrink by a factor of 1, and will wrap its content.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex shrink">
                                    <p class="block block-alpha"><strong>This is the flex-shrink target</strong></p>
                                    <p class="block block-pink">Foo bar</p>
                                    <p class="block block-yellow">Lorem ipsum</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-shrink: 0;</div>
                        </div>
                        <div className="property-define">
                            The element will not shrink: it will retain the width it needs, and not wrap its content. Its siblings will shrink to give space to the target element.<br />
                            Because the target element will not wrap its content, there is a chance for the flexbox container's content to overflow.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex shrink type2">
                                    <p class="block block-alpha"><strong>This is the flex-shrink target</strong></p>
                                    <p class="block block-pink">Foo bar</p>
                                    <p class="block block-yellow">Lorem ipsum</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-shrink: 2;</div>
                        </div>
                        <div className="property-define">
                            Because the flex-shrink value is relative, its behaviour depends on the value of the flexbox item siblings.<br />

                            In this example, the green item wants to fill 100% of the width. The space it needs is taken from its two siblings, and is divided in 4:
                            <ul>
                                <li>3 quarters are taken from the red item</li>
                                <li>1 quarter is taken from the yellow item</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex shrink type3">
                                    <p class="block block-alpha">width<br /><strong>100%</strong></p>
                                    <p class="block block-pink">shrink<br /><strong
                                    >3</strong></p>
                                    <p class="block block-yellow">shrink<br /><strong>
                                        1</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={flexWrapRef}>
                <div className="name">flex-wrap</div>
                <div className="define">Defines if flexbox items appear on a single line or on multiple lines within a flexbox container.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-wrap: nowrap;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The flexbox items will remain on a single line, no matter what, and will eventually overflow if needed.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex wrap nowrap">
                                    <p class="block block-alpha"><strong>1.</strong><br />One</p>
                                    <p class="block block-beta"><strong>2.</strong><br />Two</p>
                                    <p class="block block-pink"><strong>3.</strong><br />Three</p>
                                    <p class="block block-yellow"><strong>4.</strong><br />Four</p>
                                    <p class="block block-orange"><strong>5.</strong><br />Five</p>
                                    <p class="block block-purple"><strong>6.</strong><br />Six</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-wrap: wrap;</div>
                        </div>
                        <div className="property-define">
                            The flexbox items will be distributed among multiple lines if needed.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex wrap onwrap">
                                    <p class="block block-alpha"><strong>1.</strong><br />One</p>
                                    <p class="block block-beta"><strong>2.</strong><br />Two</p>
                                    <p class="block block-pink"><strong>3.</strong><br />Three</p>
                                    <p class="block block-yellow"><strong>4.</strong><br />Four</p>
                                    <p class="block block-orange"><strong>5.</strong><br />Five</p>
                                    <p class="block block-purple"><strong>6.</strong><br />Six</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>flex-wrap: wrap-reverse;</div>
                        </div>
                        <div className="property-define">
                            The flexbox items will be distributed among multiple lines if needed. Any additional line will appear before the previous one.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex wrap wrap-reverse">
                                    <p class="block block-alpha"><strong>1.</strong><br />One</p>
                                    <p class="block block-beta"><strong>2.</strong><br />Two</p>
                                    <p class="block block-pink"><strong>3.</strong><br />Three</p>
                                    <p class="block block-yellow"><strong>4.</strong><br />Four</p>
                                    <p class="block block-orange"><strong>5.</strong><br />Five</p>
                                    <p class="block block-purple"><strong>6.</strong><br />Six</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <div className="css-reference-content" ref={justifyContentRef}>
                <div className="name">justify-content</div>
                <div className="define">Defines how flexbox/grid items are aligned according to the main axis, within a flexbox/grid container.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>justify-content: flex-start;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The flexbox/grid items are pushed towards the start of the container's main axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex justify-content type1">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>justify-content: flex-end;</div>
                        </div>
                        <div className="property-define">
                            The flexbox/grid items are pushed towards the end of the container's main axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex justify-content type2">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>justify-content: center;</div>
                        </div>
                        <div className="property-define">
                            The flexbox/grid items are centered along the container's main axis.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex justify-content type3">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>justify-content: space-between;</div>
                        </div>
                        <div className="property-define">
                            The remaining space is distributed between the flexbox/grid items.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex justify-content type4">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>justify-content: space-around;</div>
                        </div>
                        <div className="property-define">
                            The remaining space is distributed around the flexbox/grid items: this adds space before the first item and after the last one.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex justify-content type5">
                                    <p class="block block-alpha"><strong>1.</strong> One</p>
                                    <p class="block block-beta"><strong>2.</strong> Two</p>
                                    <p class="block block-pink"><strong>3.</strong> Three</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="css-reference-content" ref={orderRef}>
                <div className="name">order</div>
                <div className="define">Defines the order of a flexbox item.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>order: 0;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The order of the flexbox items is the one defined in the HTML code.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex order type1">
                                    <p class="block block-alpha">One<br/>order:0</p>
                                    <p class="block block-beta">Two<br />order:0</p>
                                    <p class="block block-pink"><strong>Target<br />order: 0</strong></p>
                                    <p class="block block-yellow">Four<br />order:0</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>order: 1;</div>
                        </div>
                        <div className="property-define">
                            The order is relative to the flexbox item's siblings. The final order is defined when all individual flexbox item order values are taken into account.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex order type2">
                                    <p class="block block-alpha">One<br />order:0</p>
                                    <p class="block block-beta">Two<br />order:0</p>
                                    <p class="block block-pink"><strong>Target<br />order: 1</strong></p>
                                    <p class="block block-yellow">Four<br />order:0</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>order: -1;</div>
                        </div>
                        <div className="property-define">
                            You can use negative values.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex order type3">
                                    <p class="block block-alpha">One<br />order:0</p>
                                    <p class="block block-beta">Two<br />order:0</p>
                                    <p class="block block-pink"><strong>Target<br />order: -1</strong></p>
                                    <p class="block block-yellow">Four<br />order:0</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>order: 9;</div>
                        </div>
                        <div className="property-define">
                            You can set a different value for each flexbox item.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output flex order type4">
                                    <p class="block block-alpha">One<br />order: 13</p>
                                    <p class="block block-beta">Two<br />order: -7</p>
                                    <p class="block block-pink"><strong>Target<br />order: 9</strong></p>
                                    <p class="block block-yellow">Four<br />order: 5</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Flexbox;