import React from 'react';
import { Helmet } from 'react-helmet';
import Form from './Form';
import ColorPicker from './ColorPicker';
import './ColorPicker.scss';

const TITLE = 'RGB / HEX 변환하기';

const ColorConvert = ({ input, value, onChange, onPickerChange, onSubmit }) => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content="Rgb 색상값을 Hex로, 색상코드를 rgb값으로 변환하기, 컬러피커로 색상코드 헥사코드 찾기. rgb color codes, hex format converter  " />
      </Helmet>

      <div className="top-title design">
        <h2>RGB↔HEX 변환하기</h2>
        <p>RGB to HEX Tool은 RGB색상을 색상코드/HEX로 변환 (또는 색상코드/HEX를 RGB색상 변환) 해주는 도구입니다.</p>
      </div>
      
      <div className="content-wrap">
        <div className="sz-wrap">
          {/* ad 영역 */}
          <div className="ad-wrap" />

          <Form input={input} value={value} onChange={onChange} onSubmit={onSubmit} />

          <ColorPicker input={input} value={value} onPickerChange={onPickerChange} />
        </div>
      </div>
    </>
  );
};

export default ColorConvert;