import React from 'react';
import './Common.scss';

const Footer = () => {
  return (
    <div id="footer">
      <p>contact : toolo@syneez.com</p>
      <p>Copyright 2019. SYNEEZ. All rights reserved.</p>
    </div>
  );
};

export default Footer;