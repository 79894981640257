import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useState } from 'react';

const TITLE = 'CSS Reference - Positioning';

const Positioning = ({ input, value, onChange, onPickerChange, onSubmit }) => {


    // 서브 카테고리 연결
    const bottomRef = useRef(null);
    const leftRef = useRef(null);
    const positionRef = useRef(null);
    const rightRef = useRef(null);
    const topRef = useRef(null);
    const zindexRef = useRef(null);

    const scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // 클릭 시 복사
    const clickCopyText = (event) => {
        const textToCopy = event.target.innerText;
        navigator.clipboard.writeText(textToCopy).then(() => {
            event.target.classList.add('copied');
            setTimeout(() => {
                event.target.classList.remove('copied');
            }, 1000);
        }).catch(err => {
            console.error('텍스트 복사 실패', err);
        });
    }
    
    // 토글 버튼
    const [positionFixed, setPositionFixed] = useState(false);
    const toggleClass = () => {
        setPositionFixed(!positionFixed);
    };

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
                <meta name="description" content="" />
            </Helmet>

            <div className="top-title css">
                <h2>CSS Reference</h2>
                <p>다양한 CSS 속성 예시를 확인하고, 간단하게 복사해서 활용해보세요.</p>
            </div>

            <div className="menu-wrap">
                <div className="menu-type css">
                    <ul>
                        <Link to="/css-ref"><li>All</li></Link>
                        <Link to="/css-ref/animations"><li>Animations</li></Link>
                        <Link to="/css-ref/backgrounds"><li>Backgrounds</li></Link>
                        <Link to="/css-ref/boxmodel"><li>Box model</li></Link>
                        <Link to="/css-ref/flexbox"><li>Flexbox</li></Link>
                        <Link to="/css-ref/cssgrid"><li>CSS Grid</li></Link>
                        <Link to="/css-ref/positioning"><li className='checked'>Positioning</li></Link>
                        <Link to="/css-ref/transitions"><li>Transitions</li></Link>
                        <Link to="/css-ref/typography"><li>Typography</li></Link>
                    </ul>
                </div>

                <div className="menu-type css category">
                    <ul>
                        <li onClick={() => scrollToRef(bottomRef)}>bottom</li>
                        <li onClick={() => scrollToRef(leftRef)}>left</li>
                        <li onClick={() => scrollToRef(positionRef)}>position</li>
                        <li onClick={() => scrollToRef(rightRef)}>right</li>
                        <li onClick={() => scrollToRef(topRef)}>top</li>
                        <li onClick={() => scrollToRef(zindexRef)}>z-index</li>
                    </ul>
                </div>
            </div>

            <div className="css-reference-content" ref={bottomRef}>
                <div className="name">bottom</div>
                <div className="define">Defines the position of the element according to its bottom edge.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>bottom: auto;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will remain in its natural position.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning bottom type1">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>bottom: 20px;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position relative, the element will move upwards by the amount defined by the bottom value.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning bottom type2">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>bottom: 0;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position absolute, the element will position itself from the bottom of the first positioned ancestor.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning bottom type3">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={leftRef}>
                <div className="name">left</div>
                <div className="define">Defines the position of the element according to its left edge.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>left: auto;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will remain in its natural position.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning left type1">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>left: 80px;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position relative, the element will move left by the amount defined by the left value.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning left type2">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>left: -20px;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position absolute, the element will position itself from the left of the first positioned ancestor.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning left type3">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={positionRef}>
                <div className="name">position</div>
                <div className="define">Defines the position behavior of the element.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>position: static;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will remain in the natural flow of the page.<br />
                            As a result, it will not act as anchor point for the absolutely positioned pink block.<br />
                            Also, it will not react to the following properties:
                            <ul>
                                <li>top</li>
                                <li>bottom</li>
                                <li>left</li>
                                <li>right</li>
                                <li>z-index</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning basic type1">
                                    <p>Ancestor container</p>
                                    <div className="block block-alpha">
                                        <strong>Target element<br />position: static</strong>
                                        <div className="block block-pink">
                                            Child element<br />position: absolute<br />right: 5px<br />top: 5px
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>position: relative;</div>
                        </div>
                        <div className="property-define">
                            The element will remain in the natural flow of the page.<br />

                            It also makes the element positioned: it will act as an anchor point for the absolutely positioned pink block.<br />

                            Also, it will react to the following properties:
                            <ul>
                                <li>top</li>
                                <li>bottom</li>
                                <li>left</li>
                                <li>right</li>
                                <li>z-index</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning basic type2">
                                    <p>Ancestor container</p>
                                    <div className="block block-alpha">
                                        <strong>Target element<br />position: relative</strong>
                                        <div className="block block-pink">
                                            Child element<br />position: absolute<br />right: 5px<br />top: 5px
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>position: absolute;</div>
                        </div>
                        <div className="property-define">
                            The element will not remain in the natural flow of the page. It will position itself according to the closest positioned ancestor.<br />

                            Because it's positioned, it will act as an anchor point for the absolutely positioned pink block.<br />

                            Also, it will react to the following properties:
                            <ul>
                                <li>top</li>
                                <li>bottom</li>
                                <li>left</li>
                                <li>right</li>
                                <li>z-index</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning basic type3">
                                    <p>Ancestor container</p>
                                    <div className="block block-alpha">
                                        <strong>Target element<br />position: absolute<br />left: 0<br />top: 0</strong>
                                        <div className="block block-pink">
                                            Child element<br />right: 5px<br />top: 5px
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>position: fixed;</div>
                        </div>

                        <div className="button-toggle positioning" onClick={toggleClass}></div>

                        <div className="property-define">
                            The element will not remain in the natural flow of the page. It will position itself according to the viewport.<br />

                            Because it's positioned, it will act as an anchor point for the absolutely positioned pink block.<br />

                            Also, it will react to the following properties:
                            <ul>
                                <li>top</li>
                                <li>bottom</li>
                                <li>left</li>
                                <li>right</li>
                                <li>z-index</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning basic type4">
                                    <p>Ancestor container</p>
                                    <div className={`block block-alpha ${positionFixed ? 'clicked' : ''}`} >
                                        <strong>Target element<br />position: fixed<br />right: 0<br />top: 0</strong>
                                        <div className="block block-pink">
                                            Child element<br />position: absolute<br />right: 5px<br />top: 5px
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={rightRef}>
                <div className="name">right</div>
                <div className="define">Defines the position of the element according to its right edge.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>right: auto;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will remain in its natural position.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning right type1">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>right: 80px;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position relative, the element will move right by the amount defined by the right value.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning right type2">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>right: -20px;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position absolute, the element will position itself from the right of the first positioned ancestor.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning right type3">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={topRef}>
                <div className="name">top</div>
                <div className="define">Defines the position of the element according to its top edge.</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>top: auto;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The element will remain in its natural position.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning top type1">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>top: 20px;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position relative, the element will move downwards by the amount defined by the top value.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning top type2">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>top: 0;</div>
                        </div>
                        <div className="property-define">
                            If the element is in position absolute, the element will position itself from the top of the first positioned ancestor.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning top type3">
                                    <p>Parent container</p>
                                    <div className="natural">Natural position</div>
                                    <div className="actual">Actual position</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="css-reference-content" ref={zindexRef}>
                <div className="name">z-index</div>
                <div className="define">Defines the order of the elements on the z-axis. It only works on positioned elements (anything apart from static).</div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>z-index: auto;</div>
                            <div className="property-name-default">
                                default
                                <div className="property-name-default-hover">
                                    This is the property's default value
                                </div>
                            </div>
                        </div>
                        <div className="property-define">
                            The order is defined by the order in the HTML code:
                            <ul>
                                <li>first in the code = behind</li>
                                <li>last in the code = in front</li>
                            </ul>
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning z-index type1">
                                    <div className="block block-alpha">One</div>
                                    <div className="block block-pink">
                                        Two<br />
                                        <strong>Target</strong>
                                    </div>
                                    <div className="block block-beta">Three</div>
                                    <div className="block block-yellow">Four</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>z-index: 1;</div>
                        </div>
                        <div className="property-define">
                            The z-index value is relative to the other ones. The target element is move in front of its siblings.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning z-index type2">
                                    <div className="block block-alpha">One</div>
                                    <div className="block block-pink">
                                        Two<br />
                                        <strong>Target</strong>
                                    </div>
                                    <div className="block block-beta">Three</div>
                                    <div className="block block-yellow">Four</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents-tab">
                    <div className="contents-title">
                        <div className="property-name-wrap">
                            <div className="property-name" onClick={clickCopyText}>z-index: -1;</div>
                        </div>
                        <div className="property-define">
                            You can use negative values. The target element is move in behind its siblings.
                        </div>
                    </div>

                    <div className="contents-example">
                        <div className="contents-box-top"></div>
                        <div className="contents-box-output">
                            <div className="contents-output">
                                <div className="example-background-output positioning z-index type3">
                                    <div className="block block-alpha">One</div>
                                    <div className="block block-pink">
                                        Two<br />
                                        <strong>Target</strong>
                                    </div>
                                    <div className="block block-beta">Three</div>
                                    <div className="block block-yellow">Four</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Positioning