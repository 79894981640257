import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';

import './NanumMyeongjo.scss';

class NanumMyeongjo extends Component {
  render() {
    const {input, onChange} = this.props;

    return (
      <li>
        <Link to="/nanum_myeongjo/detail">
          <div className="dashed-wrap nanum_myeongjo">
            <h4 className="content-title">
              <span className="font-company">Naver, Sandoll, Fontrix </span>
              <span className="font-title">나눔 명조 / Nanum Myeongjo</span>
            </h4>
            <p className="font-text">{input}</p>
          </div>
        </Link>
      </li>
    );
  }
}

export default NanumMyeongjo;