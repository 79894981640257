import React, { useState } from 'react';
import Navigation from './Navigation';
import { fadeIn } from './../animation/FadeIn';
import { fadeOut } from './../animation/FadeOut';
import './Common.scss';

const Header = () => {
  const [ navState, setNavState ] = useState(false);
  
  const handleToggleNav = () => {
    let nav = !navState;
    const navEle = document.getElementById('navigation');

    if ( nav === true ) {
      fadeIn(navEle, 500);
    } else {
      fadeOut(navEle, 1000);
    }
    setNavState(nav);
  }

  return (
    <>
      <div id="header">
        <h1 className="header-logo"><a href="/">toolo</a></h1>
        <div id="headSvg" className={navState == true ? 'cross' : ''} onClick={handleToggleNav}>
          <svg viewBox="0 0 800 600">
            <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="gnbBtnTop" />
            <path d="M300,320 L540,320" id="gnbBtnMiddle" />
            <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="gnbBtnBottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
          </svg>
        </div>
      </div>
      
      <Navigation nav={navState} toggleNav={handleToggleNav} />
    </>
  );
};

export default Header;