import React, { Component } from 'react';

import './NotoSansKR.scss';
import '../Webfont.scss';

class NotoSansKRStyle extends Component {
  render() {
    const {input} = this.props;

    return (
      <ul className="font_style-list noto_sans_kr">
        <li className="weight_100">
          <span className="font_style-category">Thin 100</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
        <li className="weight_300">
          <span className="font_style-category">Light 300</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
        <li className="weight_400">
          <span className="font_style-category">Regular 400</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
        <li className="weight_500">
          <span className="font_style-category">Medium 500</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
        <li className="weight_700">
          <span className="font_style-category">Bold 700</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
        <li className="weight_900">
          <span className="font_style-category">Black 900</span>
          <p className="font_style-preview">{input}</p>
          {/* <button type="button" className="font_style_select-btn">폰트 선택</button> */}
        </li>
      </ul>
    );
  }
}

export default NotoSansKRStyle;